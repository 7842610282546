import { useEffect, useState } from "react";
import styled from "styled-components";
import { isResponseSuccess } from "../../../shared/src/api/api-contracts";
import { GetContactInfoResult } from "../../../shared/src/features/devices-feature";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { ReactComponent as ImageIcon } from "./image.svg";

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ContactItem = (props: { uuid: string; title: string }) => {
  const { uuid, title } = props;

  const [img, setImg] = useState<string | null>(null);
  const [contactInfo, setContactInfo] = useState<GetContactInfoResult | null>(
    null
  );

  const { getDocumentByRecord, getContactInfo } = useApplicationClient();

  useEffect(() => {
    //TODO add mount guard

    (async () => {
      const data = await getDocumentByRecord({ recordUuid: uuid });

      if (isResponseSuccess(data)) {
        setImg(data.payload.data);
      }

      //TODO add error handling
    })();
  }, [getDocumentByRecord, uuid]);

  useEffect(() => {
    //TODO add mount guard
    (async () => {
      const data = await getContactInfo({ uuid });

      if (isResponseSuccess(data)) {
        setContactInfo(data.payload);
      }

      //TODO add error handling
    })();
  }, [getContactInfo, uuid]);

  return (
    //TODO refactor styling
    <div
      style={{
        display: "flex",
        maxHeight: 200,
        marginBottom: 25,
        columnGap: 25
      }}
    >
      <div style={{ width: 150 }}>
        {img && (
          <img
            style={{ maxWidth: 150, maxHeight: 200, borderRadius: 8 }}
            src={`data:image/jpg;base64,${img}`}
            alt=""
          />
        )}
        {!img && (
          <div
            style={{
              display: "flex",
              width: 100,
              height: 120,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ImageIcon style={{ width: 50, height: 50, fill: "#969696" }} />
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>{title}</h2>
        <span style={{ marginBottom: 4 }}>{contactInfo?.name}</span>
        <span style={{ marginBottom: 4 }}>{contactInfo?.phone}</span>
        <span style={{ marginBottom: 4 }}>{contactInfo?.email}</span>
      </div>
    </div>
  );
};

export const DevicesDetailSupport = (props: {
  technitianUuid?: string;
  costumerCareUuid?: string;
}) => {
  const { technitianUuid, costumerCareUuid } = props;

  return (
    <DevicesPageContext>
      {costumerCareUuid && (
        <ContactItem uuid={costumerCareUuid} title="Zákaznická podpora" />
      )}
      {technitianUuid && (
        <ContactItem uuid={technitianUuid} title="Technická podpora" />
      )}
    </DevicesPageContext>
  );
};
