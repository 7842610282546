import React from "react";
import styled from "styled-components";
import { StatusInfo } from "../eshop/basket-status-info";
import { isBasketLoadedGuard, useBasket } from "../eshop/hooks/use-basket";
import { Loader } from "../ui/loader/loader";
import { DashboardBranchcesSection } from "./dashboard-branches-section";
import { DashboardEshopSection } from "./dashboard-eshop-section";
import { DashboardMaintenancesSection } from "./dashboard-maintenances-section";


const PageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  animation: fadeIn 0.15s ease-in 1;

  padding-top: 8px;
  column-gap: 16px;
  overflow: auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    flex-direction: row;
    padding-top: 0;
  }
`;

const LoaderPageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Dashboard = () => {
  const basket = useBasket();

  if (!isBasketLoadedGuard(basket)) {
    return (
      <LoaderPageContext>
        <Loader />
      </LoaderPageContext>
    );
  }

  return (
    <PageContext>
      <Column>
        <StatusInfo
          dueAmount={basket.orderedCoffeeStatus.dueAmount}
          fulfilledAmount={basket.orderedCoffeeStatus.fulfilledAmount}
          added={basket.basketSummary.totalWeight}
        />
        <DashboardEshopSection />
      </Column>

      <Column>
        <DashboardBranchcesSection />

        <DashboardMaintenancesSection />
      </Column>
    </PageContext>
  );
};
