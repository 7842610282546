import { useCallback, useState } from "react";
import styled from "styled-components";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { GetDeviceDocumentsListItem } from "@shared/features/documents-feature";
import { DocumentType } from "@shared/domain/document.types";
import { toast } from "react-toastify";
import moment from "moment";

import { useApplicationClient } from "../root/hooks/use-application-client";
import { FiltersWithSearch, useActiveFilters } from "../ui/filters/filters";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { LoadInfiniteListProps } from "../ui/infinite-list/use-infinite-list";
import { ListHeader } from "../ui/pages/list-header";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { DocumentDownloadRow } from "../documents/document-download-row";
import { useLogger } from "../common/use-logger";
import { GetDevicesDetailResult } from "../../../shared/src/features/devices-feature";

const columns: ListColumn<GetDeviceDocumentsListItem>[] = [
  {
    title: "Datum",
    dataIndex: "createdDate",
    render: (data) => (
      <TruncatedText id={`${data.uuid}-createdDate`}>
        {moment(data.createdDate).format("DD.MM.YYYY")}
      </TruncatedText>
    ),
    minWidth: 75,
    flex: 1
  },
  {
    title: "Typ",
    dataIndex: "type",
    render: (data) => (
      <TruncatedText id={`${data.uuid}-type`}>{data.type}</TruncatedText>
    ),
    minWidth: 150,
    flex: 2
  },
  {
    title: "Popis",
    dataIndex: "title",
    render: (data) => (
      <TruncatedText id={`${data.uuid}-title`}>{data.title}</TruncatedText>
    ),
    minWidth: 300,
    flex: 3
  },
  {
    title: "Ke stažení",
    dataIndex: "downloadable",
    render: (data) =>
      data.downloadable && (
        <DocumentDownloadRow documentUuid={data.uuid} type={data.suffix} />
      ),
    minWidth: 70,
    flex: 0
  }
];

const filters = [
  {
    name: "Typ dokumentu",
    column: "type",
    values: [
      { id: DocumentType.Manual, name: "Návod" },
      { id: DocumentType.DeviceTypePhoto, name: "Fotka" },
      { id: DocumentType.RevisionList, name: "Revize" }
    ]
  }
];

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DevicesDetailDocuments = (props: {
  data: GetDevicesDetailResult;
}) => {
  const { data } = props;

  const [search, setSearch] = useState("");
  const activeFilters = useActiveFilters();
  const { getDeviceDocumentsList } = useApplicationClient();
  const logger = useLogger("DevicesDetailDocuments");

  const fetchData = useCallback(
    async (props: LoadInfiniteListProps) => {
      logger.debug(`Fetching DeviceDocuments list data`);
      const response = await getDeviceDocumentsList({
        deviceUuid: data.uuid,
        startIndex: props.startIndex,
        stopIndex: props.stopIndex,
        search: search,
        sortColumn: props.sortColumn,
        sortOrder: props.sortOrder,
        filters: activeFilters
      });
      if (isResponseSuccess(response)) {
        return response.payload;
      } else {
        logger.error(`There was an error loading list`, response);
        toast.error("Nastala chyba při načítání dat 🤕");
        return {
          count: 0,
          rows: []
        };
      }
    },
    [getDeviceDocumentsList, data, search, activeFilters, logger]
  );

  return (
    <DevicesPageContext>
      <ListHeader compact>
        <FiltersWithSearch
          filters={filters}
          search={search}
          onSearchChanged={setSearch}
        />
      </ListHeader>

      <InfiniteList
        columns={columns}
        loadRows={fetchData}
        dependencies={[fetchData]}
      />
    </DevicesPageContext>
  );
};
