import { ReactNode, useMemo, useState } from "react";

import { GlobalDataVersionContext } from "../hooks/use-globa-data-version";

export const WithGlobalDataVersion = (props: { children: ReactNode }) => {
  const [dataVersion, setDataVersion] = useState(0);

  const client = useMemo(() => {
    return {
      increase: () => setDataVersion((state) => state + 1),
      version: dataVersion,
    };
  }, [dataVersion]);

  return (
    <GlobalDataVersionContext.Provider value={client}>
      {props.children}
    </GlobalDataVersionContext.Provider>
  );
};
