import styled from "styled-components";

export const PageTitle = styled.div`
  padding: 16px;
  border: none;
  border-radius: 25px;

  display: flex;
  align-items: center;
  flex-direction: row;

  color: ${(props) => props.theme.textColor};
  font-size: 14px;
  font-weight: 600;

  background: none;
  cursor: pointer;

  transition: all 0.15s ease-in;
  transform-origin: center;

  svg {
    margin-right: 16px;

    fill: ${(props) => props.theme.textColor};
  }

  &:hover {
    transform: scale(1.002);
  }

  @media (min-width: 576px) {
    padding: 0;
    margin-bottom: 25px;
  }
`;
