import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import styled from "styled-components";

import { Button } from "../../ui/button/button";
import { Card, CardHeader, CardContent, CardFooter } from "../../ui/card/card";

const PageDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.backgroundLighter};
`;

const Img = styled.img`
  width: 100px;
  margin-bottom: 10px;
`;

const Text = styled.span`
  font-size: 13px;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 25px;
`;

export const AppNotAuthorized = () => {
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <PageDiv>
      <Card style={{ width: 320 }}>
        <CardHeader>
          <Img src="https://coffeeexperts.cz/wp-content/uploads/2020/09/logo-coffee-experts-test.png" />
          Neexistující oprávnění
        </CardHeader>

        <CardContent>
          <Text>Omlouváme se, ale nemáte oprávnění k žádnému zařízení!</Text>
        </CardContent>
        <CardFooter>
          <Button>Kontaktovat podporu</Button>
          <Button primary onClick={handleLogout}>
            Odhlásit se
          </Button>
        </CardFooter>
      </Card>
    </PageDiv>
  );
};
