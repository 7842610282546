import styled from "styled-components";

export const Text = styled.span<{ compact?: boolean }>`
  display: flex;
  margin-bottom: ${(props) => (props.compact ? "0" : "8px")};
  border-radius: 8px;
  padding: ${(props) => (props.compact ? "10px 0" : "10px 20px")};

  background: ${(props) => props.theme.backgroundLighter};

  flex-grow: 0;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.textColor};

  strong {
    min-width: 100px;
    font-size: 13px;
    margin-right: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.textContrastColor};
  }
`;
