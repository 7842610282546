import styled from "styled-components";

export const Button = styled.button<{ primary?: boolean; secondary?: boolean }>`
  height: 35px;
  border: none;
  border-radius: 25px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: ${(props) =>
    props.primary
      ? props.theme.backgroundLighter
      : props.secondary
      ? props.theme.backgroundLighter
      : props.theme.textColor};
  font-size: 12px;
  font-weight: 600;
  padding: 4px 16px;

  background-color: ${(props) =>
    props.primary
      ? props.theme.primary
      : props.secondary
      ? props.theme.textContrastColor
      : props.theme.backgroundLighter};
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  transition: transform 0.1s ease-in;
  transition: all 0.1s ease-in;

  &:disabled {
    cursor: default;
    background-color: ${(props) => props.theme.backgroundLighter};
    color: ${(props) => props.theme.textColor};
  }

  &:disabled:hover {
    cursor: default;
    background-color: ${(props) => props.theme.backgroundLighter};
    color: ${(props) => props.theme.textColor};
    transform: none;
  }

  &:hover {
    color: ${(props) => props.theme.textContrastColor};
    transform: scale(1.02);
    transform-origin: center center;
  }
`;
