import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { Dashboard } from "../../dashboard/dashboard";
import { Devices } from "../../devices/devices";
import { Documents } from "../../documents/documents";
import { Eshop } from "../../eshop/eshop";
import { WithBasket } from "../../eshop/with-basket";

const PageDiv = styled.div`
  overflow: hidden;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    padding: 25px;
  }
`;

export const AppPage = () => {
  return (
    <WithBasket>
      <PageDiv>
        <Routes>
          <Route path="/devices/*" element={<Devices />} />
          <Route path="/eshop/*" element={<Eshop />} />
          <Route path="/documents/*" element={<Documents />} />
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </PageDiv>
    </WithBasket>
  );
};
