import { useCallback, useEffect, useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthState, useAuthState } from "../root/hooks/use-auth-state";
import { Button } from "../ui/button/button";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card/card";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { Loader } from "../ui/loader/loader";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { BasketOrderSummary } from "./basket-order-summary";
import { StatusInfo } from "./basket-status-info";
import { isBasketLoadedGuard, useBasket } from "./hooks/use-basket";
import { OrderForBranchModal } from "./order-for-branch-modal";

const PageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Container = styled.div`
  flex: 1;
  display: grid;
  align-items: stretch;
  column-gap: 16px;

  @media (min-width: 576px) {
    display: flex;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  min-height: 250px;
  border-bottom: ${(props) => `1px solid ${props.theme.textColorLighter}`};
  margin-bottom: 8px;

  @media (min-width: 576px) {
    margin: 0;
    border: none;
  }
`;

type BranchListItem = AuthState["branches"][0];

const ExpiredContainer = styled.div`
  margin: 25px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 25px;
  align-items: center;
  background: ${(props) => props.theme.backgroundColor};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media (min-width: 576px) {
    width: 200px;
    box-shadow: none;
  }
`;

const ExpiredText = styled.span`
  color: ${(props) => props.theme.textColor};
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;

  @media (min-width: 576px) {
    line-height: 20px;
  }
`;

function BasketExpired() {
  const basket = useBasket();

  if (!isBasketLoadedGuard(basket)) {
    return <></>;
  }

  return (
    <PageContext>
      <ExpiredContainer>
        <Card>
          <CardHeader>Váš Košík vypršel</CardHeader>

          <CardContent>
            <ExpiredText>
              Je nám to líto, ale Váš košík vypršel. Životnost košíku je 1 den.
              Pro pokračování můžete vytvořit nový košík.
            </ExpiredText>
          </CardContent>
          <CardFooter>
            <Button primary onClick={basket.renewExpiredBasket}>
              Vytvořit nový
            </Button>
          </CardFooter>
        </Card>
      </ExpiredContainer>
    </PageContext>
  );
}

export const Basket = () => {
  const authState = useAuthState();
  const basket = useBasket();
  const navigate = useNavigate();

  // This mocks async call with prefetched data - there will never be a lot of branches and they are core to the
  // authorization settings for a given user
  const fetchData = useCallback(async () => {
    return {
      count: authState.branches.length,
      rows: authState.branches
    };
  }, [authState.branches]);

  const closeModal = useCallback(() => {
    navigate(`/eshop`);
  }, [navigate]);

  const columns: ListColumn<BranchListItem>[] = useMemo(
    () => [
      {
        title: "Název provozovny",
        dataIndex: "type",
        render: (data) => (
          <TruncatedText id={`${Math.random()}-type`}>
            {data.name}
          </TruncatedText>
        ),
        minWidth: 30,
        flex: 2
      },
      {
        title: "",
        dataIndex: "",
        render: (item) => (
          <Button
            primary
            style={{
              padding: `0 8px`,
              height: 25,
              fontSize: 11
            }}
            onClick={() => {
              navigate(`./order/${item.uuid}`);
            }}
          >
            Vybrat kávu
          </Button>
        ),
        minWidth: 90,
        flex: 0.3
      }
    ],
    [navigate]
  );

  if (!isBasketLoadedGuard(basket)) {
    return (
      <PageContext>
        <Loader />
      </PageContext>
    );
  }

  if (basket.isExpired) {
    return <BasketExpired />;
  }

  return (
    <PageContext>
      <Routes>
        <Route
          path="/order/:uuid/*"
          element={
            <OrderForBranchModal
              status={basket.orderedCoffeeStatus}
              onClose={closeModal}
            />
          }
        />
      </Routes>

      <Container>
        <Column>
          {isBasketLoadedGuard(basket) && (
            <StatusInfo
              dueAmount={basket.orderedCoffeeStatus.dueAmount}
              fulfilledAmount={basket.orderedCoffeeStatus.fulfilledAmount}
              added={basket.basketSummary.totalWeight}
            />
          )}
          <ListContainer>
            <InfiniteList
              columns={columns}
              loadRows={fetchData}
              dependencies={[fetchData]}
              withoutShadow
            />
          </ListContainer>
        </Column>
        <Column>
          <BasketOrderSummary />
        </Column>
      </Container>
    </PageContext>
  );
};
