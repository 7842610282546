import styled from "styled-components";

export const ListBody = styled.div<{ withoutShadow?: boolean }>`
  flex: 1;
  animation: fadeIn 0.1s ease-in 1;
  overflow: auto;
  background: ${(props) => props.theme.backgroundLighter};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    box-shadow: ${(props) =>
      props?.withoutShadow ? "none" : "rgba(0, 0, 0, 0.16) 0px 1px 4px"};
  }
`;
