import { ReactNode, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { AppLoading } from "./app-loading";
import { useLogger } from "../../common/use-logger";

export const WithAuthenticatedApp = (props: { children: ReactNode }) => {
  const { children } = props;

  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  const logger = useLogger("WithAuthenticatedApp");

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      logger.log(`Redirecting to login page`);
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, logger]);

  if (isLoading || !isAuthenticated) {
    return <AppLoading />;
  }

  return <>{children}</>;
};
