import { ClientConfigResult } from "@shared//features/client-config-feature";
import { createContext, useContext } from "react";

export const ApplicationConfigContext =
  createContext<ClientConfigResult | null>(null);

export const useApplicationConfig = () => {
  const client = useContext(ApplicationConfigContext);

  if (!client) {
    throw new Error(`Can not use application config outside its context`);
  }

  return client;
};
