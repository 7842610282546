import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import styled from "styled-components";
import { useAuthState } from "../hooks/use-auth-state";

import { useIsDesktop } from "../hooks/use-is-desktop";
import { ReactComponent as Icon } from "./menu.svg";
import { ReactComponent as PowerOff } from "./power_off.svg";

export const StyledHeader = styled.header`
  height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  background-color: ${(props) => props.theme.background};
  box-shadow: #0000004f 0px 0px 10px -2px;

  color: ${(props) => props.theme.textContrastColor};

  svg {
    fill: ${(props) => props.theme.textContrastColor};
  }

  @media (min-width: 576px) {
    padding: 0 25px;
    height: 60px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  @media (min-width: 576px) {
    justify-content: flex-end;
  }
`;

const EmailDiv = styled.div`
  height: 35px;

  padding-right: 25px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 700;
`;

export const Header = (props: { openMenu: () => void }) => {
  const { openMenu } = props;

  const { logout, user } = useAuth0();
  const isDesktop = useIsDesktop();
  const authState = useAuthState();

  const handleLogout = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <StyledHeader>
      <Content>
        {!isDesktop && <Icon onClick={openMenu}>Open</Icon>}
        {isDesktop && (
          <EmailDiv>
            {
              authState.branches.find(
                (item) => item.uuid === authState.mainBranchUuid
              )?.name
            }
          </EmailDiv>
        )}
        <EmailDiv>{user?.email}</EmailDiv>
        <PowerOff
          style={{ cursor: "pointer" }}
          onClick={handleLogout}
          data-testid="logout-btn"
        >
          Odhlásit se
        </PowerOff>
      </Content>
    </StyledHeader>
  );
};
