import styled from "styled-components";
import { QrReader } from "react-qr-reader";

import { Modal } from "../ui/modal/modal";
import { useLogger } from "../common/use-logger";

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
`;

const Container = styled.div`
  width: 330px;
  height: 250px;
  aspect-ratio: 135/100;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MarkerLeftTop = styled.div`
  z-index: 100;
  position: absolute;
  top: -8px;
  left: -8px;
  width: 35%;
  height: 35%;
  border-left: solid 16px black;
  border-top: solid 16px black;
`;

const MarkerRightTop = styled.div`
  z-index: 100;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 35%;
  height: 35%;
  border-right: solid 16px black;
  border-top: solid 16px black;
`;

const MarkerLeftBottom = styled.div`
  z-index: 100;
  position: absolute;
  bottom: -8px;
  left: -8px;
  width: 35%;
  height: 35%;
  border-left: solid 16px black;
  border-bottom: solid 16px black;
`;

const MarkerRightBottom = styled.div`
  z-index: 100;
  position: absolute;
  bottom: -8px;
  right: -8px;
  width: 35%;
  height: 35%;
  border-right: solid 16px black;
  border-bottom: solid 16px black;
`;

export const ScanQR = (props: {
  onClose: () => void;
  setSearch: (search: string) => void;
}) => {
  const logger = useLogger("ScanQR");

  return (
    <Modal onClose={props.onClose}>
      <Page onClick={props.onClose}>
        <Container>
          <MarkerLeftTop />
          <MarkerRightTop />
          <MarkerRightBottom />
          <MarkerLeftBottom />
          <QrReader
            onResult={(result, error) => {
              if (result) {
                const text = result.getText();
                logger.debug(`QR code succesfully read - ${text}`);
                props.setSearch(text);
                props.onClose();
              }

              // Seems we don't need this as there is actually nothing to debug
              if (error) {
                logger.debug(`Could not read qr code`, error);
              }
            }}
            constraints={{ facingMode: "environment" }}
            containerStyle={{ flex: 1 }}
          />
        </Container>
      </Page>
    </Modal>
  );
};
