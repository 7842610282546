import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Modal } from "./modal";

interface GlobalModalContextValue {
  openModal: (component: ReactNode, title?: string) => void;
  closeModal: () => void;
  previousTitle: string;
  title: string;
}

const GlobalModalContext = createContext<GlobalModalContextValue | null>(null);

export const useGlobalModal = () => {
  const context = useContext(GlobalModalContext);

  if (!context) {
    throw new Error("Can not use global modal outside app context.");
  }

  return context;
};

export const WithGlobalAppModal = (props: { children: ReactNode }) => {
  const { children } = props;

  const [modalComponent, setComponent] = useState<
    { component: ReactNode; title: string }[]
  >([]);

  const closeModal = useCallback(() => {
    setComponent((state) => state.slice(0, state.length - 1));
  }, []);

  const contextValue = useMemo(
    () => ({
      openModal: (component: ReactNode, title = "") => {
        setComponent((state) => [...state, { component, title }]);
      },
      closeModal,
      title: modalComponent[modalComponent.length - 1]?.title,
      previousTitle: modalComponent[modalComponent.length - 2]?.title,
    }),
    [closeModal, modalComponent]
  );

  return (
    <GlobalModalContext.Provider value={contextValue}>
      {children}
      {modalComponent[modalComponent.length - 1] && (
        <Modal onClose={closeModal}>
          {modalComponent.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  visibility:
                    modalComponent.length - 1 === index ? "visible" : "hidden",
                  position:
                    modalComponent.length - 1 === index
                      ? undefined
                      : "absolute",
                }}
              >
                {item.component}
              </div>
            );
          })}
        </Modal>
      )}
    </GlobalModalContext.Provider>
  );
};
