import styled from "styled-components";

import { GetDevicesDetailResult } from "@shared/features/devices-feature";
import { Text } from "../ui/text/text";

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    display: flex;
  }
`;

const Column = styled.div`
  padding: 0 8px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  img {
    border-radius: 8px;
  }

  @media (min-width: 576px) {
    padding: 0;

    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      margin-left: 25px;
    }

    img {
      min-height: 500px;
      max-height: 700px;
      object-fit: contain;
    }
  }
`;

const Image = styled.img``;

export const DevicesDetailGeneral = (props: {
  data: GetDevicesDetailResult;
}) => {
  return (
    <Container>
      <Column>
        <Text>
          <strong>Typ Zařízení:</strong>
          <span>{props.data.type}</span>
        </Text>
        <Text>
          <strong>Popis:</strong>
          {props.data.description}
        </Text>
        <Text>
          <strong>Provozovna:</strong>
          <span>{props.data.branchName}</span>
        </Text>
        <Text>
          <strong>Umístění:</strong>
          <span>{props.data.place}</span>
        </Text>
        <Text>
          <strong>Poznámka:</strong>
          {props.data.note}
        </Text>
      </Column>
      {props.data.image && (
        <Column>
          <Image src={`data:image/jpg;base64,${props.data.image}`} />
        </Column>
      )}
    </Container>
  );
};
