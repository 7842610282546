import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background: ${(props) => props.theme.overlay};
`;

const OverlayContainer = styled.div`
  background: #7070704f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1001;
`;

const ModalHolder = styled.div`
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  animation: fadeIn 0.15s ease-in 1;
  pointer-events: all;

  @media (min-width: 576px) {
    width: auto;
    height: auto;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Modal = (props: { children: ReactNode; onClose?: () => void }) => {
  const { children, onClose } = props;

  return createPortal(
    <>
      <Overlay onClick={onClose} />
      <OverlayContainer>
        <ModalHolder>{children}</ModalHolder>
      </OverlayContainer>
    </>,
    document.getElementById("modal") as HTMLDivElement
  );
};
