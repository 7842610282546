import { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { GetDevicesListItem } from "@shared/features/devices-feature";
import { toast } from "react-toastify";

import { useApplicationClient } from "../root/hooks/use-application-client";
import { useAuthState, useHasWriteAccess } from "../root/hooks/use-auth-state";
import { Button } from "../ui/button/button";
import { FiltersWithSearch, useActiveFilters } from "../ui/filters/filters";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { LoadInfiniteListProps } from "../ui/infinite-list/use-infinite-list";
import { ListHeader } from "../ui/pages/list-header";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { ScanQR } from "./scan-qr";
import { useLogger } from "../common/use-logger";

const useFiltersConfig = () => {
  const authState = useAuthState();

  return useMemo(() => {
    return [
      {
        name: "Provozovny",
        column: "branch.uuid",
        values: authState.branches.map((item) => ({
          id: item.uuid,
          name: item.name
        }))
      }
    ];
  }, [authState]);
};

const useColumns = () => {
  const navigate = useNavigate();
  const hasWriteAccess = useHasWriteAccess();

  return useMemo(() => {
    const columns: ListColumn<GetDevicesListItem>[] = [
      {
        title: "Evidencni cislo",
        dataIndex: "registrationNumber",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-registrationNumber`}>
            {data.registrationNumber}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1,
        sortable: true
      },
      {
        title: "Provozovna",
        dataIndex: "branch.name",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-branchName`}>
            {data.branchName}
          </TruncatedText>
        ),
        minWidth: 160,
        flex: 3,
        sortable: true
      },
      {
        title: "Typ zařízení",
        dataIndex: "deviceTypeName",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-deviceTypeName`}>
            {data.deviceTypeName}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1
      },
      {
        title: "Umístění",
        dataIndex: "place",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-place`}>{data.place}</TruncatedText>
        ),
        minWidth: 100,
        flex: 1
      },
      {
        title: "Popis",
        dataIndex: "description",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-description`}>
            {data.description}
          </TruncatedText>
        ),
        minWidth: 200,
        flex: 1
      },
      {
        title: "",
        dataIndex: "",
        render: (row) => (
          <>
            {hasWriteAccess && (
              <Button
                primary
                style={{ padding: `4px 8px`, height: 26, marginLeft: 16 }}
                onClick={(e) => {
                  navigate(`/devices/${row.uuid}/order/`);
                  e.stopPropagation();
                }}
              >
                Servis
              </Button>
            )}
          </>
        ),
        minWidth: 70,
        flex: 0
      }
    ];

    return columns;
  }, [navigate, hasWriteAccess]);
};

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DevicesList = () => {
  const [qrOpened, setQrOpened] = useState(false);
  const api = useApplicationClient();
  const activeFilters = useActiveFilters();
  const navigate = useNavigate();
  const columns = useColumns();
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useFiltersConfig();
  const logger = useLogger("DevicesList");

  const fetchData = useCallback(
    async (props: LoadInfiniteListProps) => {
      const response = await api.getDevices({
        startIndex: props.startIndex,
        stopIndex: props.stopIndex,
        search: searchParams.get(`s`) || "",
        sortColumn: props.sortColumn,
        sortOrder: props.sortOrder,
        filters: activeFilters
      });

      if (isResponseSuccess(response)) {
        logger.debug(
          `Fetched devices list data from ${props.startIndex} to ${props.stopIndex}`
        );
        return response.payload;
      } else {
        toast.error("Nastala chyba při načítání dat 🤕");
        logger.error(`There was an error loading devices list`, response);
        return {
          count: 0,
          rows: []
        };
      }
    },
    [searchParams, activeFilters, api, logger]
  );

  const goToDetail = useCallback(
    (_e: unknown, row: GetDevicesListItem) => {
      navigate(`./${row.uuid}`);
    },
    [navigate]
  );

  const setSearch = useCallback(
    (s: string) => {
      setSearchParams({ s });
    },
    [setSearchParams]
  );

  const openQr = useCallback(() => {
    setQrOpened(true);
  }, []);

  const closeQr = useCallback(() => {
    setQrOpened(false);
  }, []);

  return (
    <>
      {qrOpened && <ScanQR onClose={closeQr} setSearch={setSearch} />}
      <DevicesPageContext>
        <ListHeader>
          <FiltersWithSearch
            filters={filters}
            search={searchParams.get(`s`) || ""}
            onSearchChanged={setSearch}
          />

          <Button primary onClick={openQr}>
            Skenovat QR kód
          </Button>
        </ListHeader>

        <InfiniteList
          columns={columns}
          loadRows={fetchData}
          dependencies={[fetchData]}
          onRowClick={goToDetail}
        />
      </DevicesPageContext>
    </>
  );
};
