import { ReactNode, useEffect, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import { ClientConfigResult } from "../../../../shared/src/features/client-config-feature";
import { useLogger } from "../../common/use-logger";
import { ApplicationConfigContext } from "../hooks/use-application-config";
import { AppError } from "./app-error";
import { AppLoading } from "./app-loading";
import { ApplicationTheme, GlobalStyles } from "./global-styles";

export const WithApplicationConfig = (props: { children: ReactNode }) => {
  const [config, setConfig] = useState<ClientConfigResult | null>(null);
  const [error, setError] = useState<string | null>(null);
  const loadingRef = useRef(false);

  const logger = useLogger("useApplicationConfig");

  useEffect(() => {
    (async () => {
      if (loadingRef.current) {
        return;
      }
      loadingRef.current = true;
      try {
        const result = await fetch("/api/config");
        const { data } = await result.json();
        setConfig(data);
        logger.log(
          `Running application version ${data.version}, ${data.versionTimestamp}`
        );
      } catch (e) {
        logger.error(`Error fetching config`, e);
        setError(`Error Loading Page`);
      }
      loadingRef.current = false;
    })();
  }, [logger]);

  if (!config) {
    return (
      <ThemeProvider theme={ApplicationTheme}>
        <GlobalStyles />
        <AppLoading />
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={ApplicationTheme}>
        <GlobalStyles />
        <AppError error={error} />;
      </ThemeProvider>
    );
  }

  return (
    <ApplicationConfigContext.Provider value={config}>
      {props.children}
    </ApplicationConfigContext.Provider>
  );
};
