import { createGlobalStyle } from "styled-components";

export const ApplicationTheme = {
  primary: "#e14e12",
  confirm: "#168039",
  warn: "#CD0402",
  overlay: "#000000bd",
  background: "#f5f0dc",
  backgroundDarker: "#d7d2b9",
  backgroundLighter: "white",
  backgroundShade: "#f4f4f4",
  backgroundShadeDarker: "#dfdfdf",
  textColor: "#5a5a5a",
  textColorLighter: "#969696",
  textColorDarker: "#151515",
  textContrastColor: "#6e130b"
} as const;

export const GlobalStyles = createGlobalStyle`
body,
html,
#root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  background: $page-bg-color;
  user-select: none;
  background: white;
  animation: fadeIn 0.15s ease-in 1;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info:  ${(props) => (props.theme as typeof ApplicationTheme).primary};
  --toastify-color-success: ${(props) => (props.theme as typeof ApplicationTheme).confirm};
  --toastify-color-warning: #f1c40f;
  --toastify-color-error:  ${(props) => (props.theme as typeof ApplicationTheme).warn};
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: 'Raleway', sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: ${(props) =>
    (props.theme as typeof ApplicationTheme).textColor};

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}


div, header, nav, input {
  box-sizing: border-box;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;
