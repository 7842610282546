import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { CreateAuth0ResetLinkProps } from "@shared//features/user-management-feature";
import { Button } from "../../ui/button/button";
import { Card, CardContent, CardFooter, CardHeader } from "../../ui/card/card";

const PageDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.backgroundLighter};
`;

const Title = styled.span`
  font-size: 23px;
  text-align: center;
`;

const Text = styled.span`
  font-size: 13px;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 25px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 16px;
  margin: 0;
  margin-bottom: 16px;
  border-radius: 8px;
  border: ${(props) => "1px solid" + props.theme.backgroundShadeDarker};

  background: white;

  font-size: 14px;
  color: ${(props) => props.theme.textColor};

  outline: none;
  font-family: "Raleway", sans-serif;

  &::placeholder {
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
  }
`;

const Img = styled.img`
  width: 100px;
  margin-bottom: 10px;
`;

const resetPassword = (props: CreateAuth0ResetLinkProps) =>
  fetch("/api/reset-password", {
    body: JSON.stringify(props),
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST"
  });

export const ResetPassword = () => {
  const [value, setValue] = useState("");
  const [sent, setSent] = useState(false);

  const navigate = useNavigate();

  const navigateBack = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSend = useCallback(() => {
    resetPassword({ email: value });
    setSent(true);
  }, [value]);

  return (
    <PageDiv>
      <Card>
        <CardHeader>
          <Img src="https://coffeeexperts.cz/wp-content/uploads/2020/09/logo-coffee-experts-test.png" />
          <Title>Zapomenuté heslo</Title>
        </CardHeader>

        {!sent && (
          <>
            <CardContent>
              <Text>
                Prosím, zadejte svou emailovou adresu, na kterou Vám pošleme
                odkaz ke změně hesla
              </Text>
              <Input
                id="email"
                type="email"
                placeholder="Zadejte Vaši emailovou adresu"
                value={value}
                onChange={(e) => setValue(e.currentTarget.value)}
              />
            </CardContent>
            <CardFooter>
              <Button onClick={navigateBack}>Zpět</Button>
              <Button primary onClick={handleSend}>
                Odeslat
              </Button>
            </CardFooter>
          </>
        )}
        {sent && (
          <>
            <CardContent>
              <Text>Žádost byla odeslána na Váš email</Text>
              <Button
                style={{ width: 90, alignSelf: "center" }}
                primary
                onClick={navigateBack}
              >
                Zpět
              </Button>
            </CardContent>
            <CardFooter />
          </>
        )}
      </Card>
    </PageDiv>
  );
};
