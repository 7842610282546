import { useCallback, useEffect, useRef, useState } from "react";
import { ListResult } from "@shared/features/list-feature";

import { useLogger } from "./../../common/use-logger";
import { Sort, SortEnum } from "./infinite-list";

const defaultCount = 2000;

export type LoadInfiniteListProps = {
  startIndex: number;
  stopIndex: number;
  sortColumn?: string;
  sortOrder?: SortEnum;
  search?: string;
};

export type LoadInfiniteList<T> = (
  props: LoadInfiniteListProps
) => Promise<ListResult<T>>;

export const useInfiniteList = <Record>(
  loadRows: LoadInfiniteList<Record>,
  dependencies: unknown[],
  sort?: Sort<Record>,
  defaultRecords?: Record[]
) => {
  const defaultItems: { [key: number]: Record } =
    defaultRecords?.reduce((memo, value, index) => {
      return { ...memo, [index]: value };
    }, {}) || {};

  const records = useRef<{ [key: number]: Record }>(defaultItems);
  const loadedRecords = useRef(0);
  const [count, setCount] = useState(defaultCount);
  const [loadingKey, setLoadingKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const logger = useLogger("useInfiniteList");

  const populateRecords = useCallback(
    (startIndex: number, stopIndex: number, rows: Record[]) => {
      for (let i = startIndex; i <= stopIndex; i++) {
        if (i - startIndex < rows.length) {
          records.current[i] = rows[i - startIndex];
        }
      }
    },
    [records]
  );

  const isRecordLoaded = useCallback(
    (index: number) => {
      return index < loadedRecords.current;
    },
    [loadedRecords]
  );

  const loadMoreRows = useCallback(
    async ({
      startIndex,
      stopIndex
    }: {
      startIndex: number;
      stopIndex: number;
    }) => {
      loadedRecords.current = stopIndex;
      try {
        const data = await loadRows({
          startIndex: startIndex - (defaultRecords?.length || 0),
          stopIndex,
          sortColumn: sort?.col.dataIndex,
          sortOrder: sort?.order
        });
        populateRecords(startIndex, stopIndex, data.rows);
        setCount(data.count + (defaultRecords?.length || 0));
        setLoading(false);
      } catch (e) {
        logger.error("Error while fetching data for infinite list", e);
      }
    },
    [loadRows, populateRecords, loadedRecords, sort, defaultRecords, logger]
  );

  const deleteRecord = useCallback((recordIndex: number) => {
    setCount((state) => state - 1);
    loadedRecords.current = loadedRecords.current - 1;
    delete records.current[recordIndex];

    const newData = Object.keys(records.current).reduce<{
      [key: number]: Record;
    }>((memo, value, index) => {
      memo[index] = records.current[parseInt(value, 10)];
      return memo;
    }, {});
    records.current = newData;
    setLoadingKey((resetCount) => resetCount + 1);
  }, []);

  useEffect(() => {
    setCount(defaultCount);
    records.current = defaultItems;
    loadedRecords.current = defaultRecords?.length || 0;
    setLoadingKey((resetCount) => resetCount + 1);
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, sort]);

  return {
    count,
    records: records.current,
    isRecordLoaded,
    loadMoreRows,
    deleteRecord,
    loadingKey,
    loading
  };
};
