import { ReactNode, useEffect, useRef, useState } from "react";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { toast } from "react-toastify";

import { useApplicationClient } from "../hooks/use-application-client";
import { AuthState, AuthstateContext } from "../hooks/use-auth-state";
import { AppLoading } from "./app-loading";
import { AppNotAuthorized } from "./app-not-authorized";
import { useLogger } from "../../common/use-logger";

export const WithAuthorizedApp = (props: { children: ReactNode }) => {
  const { children } = props;
  const [authState, setAuthState] = useState<null | AuthState>(null);
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(false);

  const api = useApplicationClient();
  const logger = useLogger("WithAuthorizedApp");

  useEffect(() => {
    (async () => {
      if (loadingRef.current) {
        return;
      }
      loadingRef.current = true;
      //TODO add mount guard
      //TODO add no permission page and error handling
      const response = await api.getWhoAmI();

      if (isResponseSuccess(response)) {
        logger.debug(`Auth state fetched`);
        setAuthState(
          response.payload
            ? {
                name: response.payload.name,
                email: response.payload.email,
                activated: response.payload.activated,
                accessLevel: response.payload.accessLevel,
                deviceUuids: response.payload.deviceUuids,
                deviceTypes: response.payload.deviceTypes,
                branches: response.payload.branches,
                mainBranchUuid: response.payload.mainBranchUuid
              }
            : null
        );
        setLoading(false);
      } else {
        toast.error("Nastala chyba při načítání profilu 🤕");
        logger.log(`There was an error loading users info`);
      }

      loadingRef.current = false;
    })();
  }, [api, logger]);

  useEffect(() => {
    (async () => {
      if (authState && !authState?.activated) {
        const response = await api.activateUser();

        if (isResponseSuccess(response)) {
          logger.debug(`User was activated`);
        } else {
          toast.error("Nastala chyba při aktivaci uživatele");
          logger.log(`There was an error activating user`);
        }
      }
    })();
  }, [authState, api, logger]);

  if (loading) {
    return <AppLoading />;
  }

  if (!authState) {
    return <AppNotAuthorized />;
  }

  return (
    <AuthstateContext.Provider value={authState}>
      {children}
    </AuthstateContext.Provider>
  );
};
