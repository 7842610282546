import styled from "styled-components";

const Tag = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 16px;
  left: 16px;
  font-size: 11px;
  font-weight: 700;
  color: #0000004f;
  line-height: 20px;
`;

export const AppVersionTag = (props: { version: string }) => {
  const { version } = props;

  return <Tag>Version - {version}</Tag>;
};
