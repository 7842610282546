import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthState, useAuthState } from "../root/hooks/use-auth-state";
import { Button } from "../ui/button/button";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { DashboardSection } from "./dashboard-section";
import { DashboardSectionTitle } from "./dashboard-section-title";

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  min-height: 250px;
  border-bottom: ${(props) => `1px solid ${props.theme.textColorLighter}`};
  margin-bottom: 8px;

  @media (min-width: 576px) {
    margin: 0;
    margin-top: 8px;
    border: none;
  }
`;

type BranchListItem = AuthState["branches"][0];

export const DashboardBranchcesSection = () => {
  const authState = useAuthState();
  const navigate = useNavigate();

  // This mocks async call with prefetched data - there will never be a lot of branches and they are core to the
  // authorization settings for a given user
  const fetchData = useCallback(async () => {
    return {
      count: authState.branches.length,
      rows: authState.branches
    };
  }, [authState.branches]);

  const columns: ListColumn<BranchListItem>[] = useMemo(
    () => [
      {
        title: "Název provozovny",
        dataIndex: "type",
        render: (data) => (
          <TruncatedText id={`${Math.random()}-type`}>
            {data.name}
          </TruncatedText>
        ),
        minWidth: 30,
        flex: 2
      },
      {
        title: "",
        dataIndex: "",
        render: (item) => (
          <>
            <Button
              primary
              style={{
                padding: `0 8px`,
                height: 25,
                fontSize: 11
              }}
              onClick={() => {
                navigate(`/eshop/basket/order/${item.uuid}`);
              }}
            >
              Vybrat kávu
            </Button>
            <Button
              style={{
                padding: `0 8px`,
                height: 25,
                fontSize: 11,
                marginLeft: 8
              }}
              onClick={() => {
                //TODO should use filter encoding
                navigate(
                  `/devices?f=${btoa(
                    JSON.stringify([
                      { column: "branch.uuid", values: [item.uuid] }
                    ])
                  )}`
                );
              }}
            >
              Zobrazit zařízení
            </Button>
            <Button
              style={{
                padding: `0 8px`,
                height: 25,
                fontSize: 11,
                marginLeft: 8
              }}
              onClick={() => {
                //TODO should use filter encoding
                navigate(
                  `/documents?f=${btoa(
                    JSON.stringify([
                      { column: "branches", values: [item.uuid] }
                    ])
                  )}`,
                  {}
                );
              }}
            >
              Zobrazit dokumenty
            </Button>
          </>
        ),
        minWidth: 318,
        flex: 0
      }
    ],
    [navigate]
  );

  return (
    <DashboardSection>
      <DashboardSectionTitle>Vaše provozovny</DashboardSectionTitle>

      <ListContainer>
        <InfiniteList
          columns={columns}
          loadRows={fetchData}
          dependencies={[fetchData]}
          withoutShadow
        />
      </ListContainer>
    </DashboardSection>
  );
};
