import { createContext, useContext } from "react";

export type GlobalDataVersion = { version: number; increase: () => void };

export const GlobalDataVersionContext = createContext<GlobalDataVersion | null>(
  null
);

export const useGlobalDataVersion = () => {
  const context = useContext(GlobalDataVersionContext);

  if (!context) {
    throw new Error(`Can not use application context outside its context`);
  }

  return context;
};
