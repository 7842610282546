import ReactSelect from "react-select";
import styled from "styled-components";
import { ApplicationTheme } from "../../root/components/global-styles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.warn};
  font-size: 13px;
  font-weight: 700;
  padding: 0 8px;
  margin-top: 8px;
`;

export function Select<T>(props: {
  placeholder?: string;
  options: T[];
  value: T | null;
  error?: string;
  touched?: boolean;
  onChange: (newValue: T | null) => void;
  onBlur: (e: React.FocusEvent<unknown>) => void;
  getOptionLabel: (item: T) => string;
  getOptionValue: (item: T) => string;
}) {
  const {
    getOptionLabel,
    getOptionValue,
    onBlur,
    placeholder,
    options,
    onChange,
    value,
    error,
    touched
  } = props;

  return (
    <Container>
      <ReactSelect
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: error && touched ? ApplicationTheme.warn : "#e14e12",
            primary75: "#e14d12ab",
            primary50: "#e14d1280",
            primary25: "#e14d1215"
          }
        })}
        styles={{
          container: (provided) => ({
            ...provided
          }),
          control: (provided) => ({
            ...provided,
            borderRadius: 5,
            fontSize: 14,
            fontWeight: 500,
            color: `red`
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: `4px 16px`,
            color: "black"
          }),
          menuList: (provided) => ({
            ...provided,
            fontSize: 14,
            fontWeight: 500
          }),
          indicatorSeparator: () => ({
            display: "none"
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
            paddingRight: 15
          })
        }}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
}
