import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useMemo } from "react";

import { useLogger } from "../../common/use-logger";
import {
  ApplicationClientContext,
  buildApplicationClient
} from "../hooks/use-application-client";

export const WithApplicationClient = (props: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0();
  const logger = useLogger("WithApplicationClient");

  const client = useMemo(() => {
    return buildApplicationClient(getAccessTokenSilently, logger.error);
  }, [getAccessTokenSilently, logger]);

  return (
    <ApplicationClientContext.Provider value={client}>
      {props.children}
    </ApplicationClientContext.Provider>
  );
};
