import { useCallback } from "react";
import styled from "styled-components";
import { RoutedTabs } from "../ui/tabs/tabs";
import { Basket } from "./basket";
import { OrderHistory } from "./order-history";

const TABS = [
  { label: "Košík", path: "basket" },
  { label: "Historie objednávek", path: "orders" }
] as const;

const PageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  animation: fadeIn 0.15s ease-in 1;

  padding-top: 8px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    padding-top: 0;
  }
`;

export function Eshop() {
  const renderTabs = useCallback((tab: string) => {
    switch (tab) {
      case TABS[0].path:
        return <Basket />;
      case TABS[1].path:
        return <OrderHistory />;
    }
  }, []);

  return (
    <PageContext>
      <RoutedTabs tabs={TABS} renderTabs={renderTabs} compact />
    </PageContext>
  );
}
