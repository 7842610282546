import styled from "styled-components";

import { Loader } from "../../ui/loader/loader";
import { StyledHeader } from "./header";
import logo from "./logo.png";

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;

const AppDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  flex: 1;
`;

export const AppLoading = () => (
  <AppDiv>
    <ContentDiv>
      <StyledHeader />
      <Content>
        <img src={logo} alt="Logo" />
        <Loader />
      </Content>
    </ContentDiv>
  </AppDiv>
);
