/**
 * RecordType -> Entity mapping - Possible DocumentTypes
 *
 *  "Typy zařízení" = DeviceTypes - Manual, DeviceTypePhoto
 *  "Paušální poplatky" = Branches - Contract
 *  "Vydané doklady" = Invoices  - Invoice
 *  "Adresář" = Branches - TechnicianPhoto, CustomerCarePhoto
 *  "Servisní zakázky" = DeviceMaintenances - MaintenancesList
 *
 *  All others go to Unkown
 */

// TODO there will be one more type - Dokumenty Revize - needs to be added in the data / API
export enum DocumentType {
  // Contract belongs to he mainBranch of the user, it is shown in the User's Documents list
  /** MainBranch Documents */
  Contract = "KZ Smlouva + dodatek",

  /** Invoice */
  // Shown in Documents list
  Invoice = "KZ Vydaná faktura",

  /** Device Documents */
  RevisionList = "KZ Revize",

  /** DeviceMaintenance Documents */
  // Document regarding some DeviceMaintenance, it it shown in list
  // of device maintenances as downloadable document
  MaintenanceList = "KZ Servisní list",

  /** Device Type Documents */
  // Shown in Device detail general page
  DeviceTypePhoto = "KZ Foto typ zařízení",
  // Shown in Device detail documents & documents
  Manual = "KZ Návod",

  /** Branch Documents */
  // Techinitian and CustomerCare photo are shown in the device detail and possibly dashboard
  // These are assigned to a given Main Branch
  TechnicianPhoto = "KZ Foto Servisní technik",
  CustomerCarePhoto = "KZ Foto CC",
  DeliveryList = "KZ Dodací list",

  /** Eshop documents */
  ProductPhoto = "KZ Eshop",

  /** Unknown - all documents that are not used in the app, but for some reason would appear in VarioAPI */
  Unknown = "Neznámý typ"
}

export enum DocumentSuffix {
  Pdf = "pdf",
  Jpg = "jpg",
  Unknown = "Unknown"
}
