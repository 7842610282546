import { ReactNode } from "react";

import { BasketContext, useBuildBasketClient } from "./hooks/use-basket";

export const WithBasket = (props: { children: ReactNode }) => {
  const basketClient = useBuildBasketClient();

  return (
    <BasketContext.Provider value={basketClient}>
      {props.children}
    </BasketContext.Provider>
  );
};
