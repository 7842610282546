import { GetDevicesDetailResult } from "@shared//features/devices-feature";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLogger } from "../common/use-logger";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { useHasWriteAccess } from "../root/hooks/use-auth-state";
import { Button } from "../ui/button/button";
import { Loader } from "../ui/loader/loader";
import { Modal } from "../ui/modal/modal";
import { CardHeader, CardTitle, DetailCard } from "../ui/pages/detail-card";
import { PageTitle } from "../ui/pages/page-title";
import { RoutedTabs } from "../ui/tabs/tabs";
import { ReactComponent as BackIcon } from "./arrow-back.svg";
import { DevicesDetailConfiguration } from "./devices-detail-configuration";
import { DevicesDetailDocuments } from "./devices-detail-documents";
import { DevicesDetailGeneral } from "./devices-detail-general";
import { DevicesDetailMaintanences } from "./devices-detail-maintenances";
import { DevicesDetailSupport } from "./devices-detail-support";
import { OrderMaintenance } from "./order-maintanence";

const TABS = [
  { label: "Obecné", path: "general" },
  { label: "Konfigurace", path: "configuration" },
  { label: "Provedené servisy", path: "maintenances" },
  { label: "Dokumenty", path: "documents" },
  { label: "Kontaktní osoby", path: "support" }
] as const;

export const DevicesDetail = () => {
  const [data, setData] = useState<GetDevicesDetailResult | null>(null);
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(false);

  const api = useApplicationClient();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const hasWriteAccess = useHasWriteAccess();
  const logger = useLogger("DevicesDetail");

  const onClose = useCallback(() => {
    navigate("./", { replace: true });
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate("../", { replace: true });
  }, [navigate]);

  const openOrderMaintanceModal = useCallback(() => {
    navigate("./order");
  }, [navigate]);

  const renderTabs = useCallback(
    (tab: string) => {
      if (!data) {
        return <></>;
      }
      switch (tab) {
        case TABS[0].path:
          return <DevicesDetailGeneral data={data} />;
        case TABS[1].path:
          return <DevicesDetailConfiguration data={data} />;
        case TABS[2].path:
          return <DevicesDetailMaintanences data={data} />;
        case TABS[3].path:
          return <DevicesDetailDocuments data={data} />;
        case TABS[4].path:
          return (
            <DevicesDetailSupport
              costumerCareUuid={data.customerCareUuid}
              technitianUuid={data.technicianUuid}
            />
          );
      }
    },
    [data]
  );

  useEffect(() => {
    if (!uuid || loadingRef.current) {
      return;
    }

    loadingRef.current = true;

    (async () => {
      const response = await api.getDetail({ uuid });

      if (isResponseSuccess(response)) {
        logger.debug(`Fetched device detail for ${uuid}`);
        setData(response.payload);
        setLoading(false);
      } else {
        toast.error("Nastala chyba při načítání dat 🤕");
        logger.error(`Error loading device detail`, response);
      }

      loadingRef.current = false;
    })();
  }, [uuid, api, logger]);

  return (
    <>
      {loading && <Loader />}
      {data && !loading && (
        <>
          <Routes>
            <Route
              path="/order"
              element={
                <Modal onClose={onClose}>
                  {data && <OrderMaintenance data={data} />}
                  {!data && <Loader />}
                </Modal>
              }
            />
          </Routes>
          <PageTitle onClick={goBack}>
            <BackIcon />
            <span>Zpět na zařízení</span>
          </PageTitle>

          <DetailCard>
            <CardHeader>
              <CardTitle>{data?.serialNumber}</CardTitle>

              {hasWriteAccess && (
                <Button primary onClick={openOrderMaintanceModal}>
                  Objednat Servis
                </Button>
              )}
            </CardHeader>

            <RoutedTabs tabs={TABS} renderTabs={renderTabs} />
          </DetailCard>
        </>
      )}
    </>
  );
};
