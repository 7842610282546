import { PriceType } from "@shared/domain/products.types";
import { useCallback } from "react";
import styled from "styled-components";
import { Button } from "../ui/button/button";
import { isBasketLoadedGuard, useBasket } from "./hooks/use-basket";

const BranchItemContainer = styled.span`
  display: flex;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  padding: 0 8px;
  color: ${(props) => props.theme.textColor};
`;

const BranchItemAmount = styled.span`
  min-width: 50px;
`;

const BranchItemTitle = styled.span`
  flex: 1; ;
`;

const BranchItemPrice = styled.span`
  min-width: 150px;
`;

//TODO refactor this
export function BranchItem(props: {
  amount: number;
  branchUuid?: string;
  productUuid?: string;
  totalPrice: number;
  title: string;
  type: PriceType.CZK | PriceType.PTS;
}) {
  const { amount, branchUuid, productUuid, totalPrice, title, type } = props;

  const basket = useBasket();

  const removeFromBasket = useCallback(async () => {
    if (!isBasketLoadedGuard(basket) || !branchUuid || !productUuid) {
      throw new Error(`Basket is not loaded`);
    }

    await basket.removeItemTypeFromBasket({
      branchUuid,
      productUuid,
      paymentType: type
    });
  }, [basket, branchUuid, productUuid, type]);

  return (
    <BranchItemContainer>
      <BranchItemAmount>{amount}x</BranchItemAmount>
      <BranchItemTitle>{title}</BranchItemTitle>
      <BranchItemPrice>
        {totalPrice.toLocaleString()}
        {type === PriceType.CZK ? ` Kč` : " Bodů (0 Kč)"}
      </BranchItemPrice>
      {branchUuid && productUuid && (
        <Button
          primary
          onClick={removeFromBasket}
          style={{
            padding: `4px 8px`,
            height: 26,
            marginLeft: 16,
            fontSize: 10
          }}
        >
          Odebrat
        </Button>
      )}
    </BranchItemContainer>
  );
}

//TODO refactor this
export function TransportItem(props: {
  amount: number;
  totalPrice: number;
  title: string;
  type: PriceType.CZK | PriceType.PTS;
}) {
  const { amount, totalPrice, title, type } = props;

  return (
    <BranchItemContainer>
      <BranchItemAmount>{amount}x</BranchItemAmount>
      <BranchItemTitle>{title}</BranchItemTitle>
      <BranchItemPrice>
        {totalPrice.toLocaleString()}
        {type === PriceType.CZK ? ` Kč` : " Bodů (0 Kč)"}
      </BranchItemPrice>
    </BranchItemContainer>
  );
}
