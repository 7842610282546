import { PriceType } from "@shared/domain/products.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { isResponseSuccess } from "../../../shared/src/api/api-contracts";
import { assertExistence } from "../../../shared/src/common/assert";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { useHasWriteAccess } from "../root/hooks/use-auth-state";
import { ReactComponent as AddIcon } from "./add.svg";
import {
  BasketBranchProduct,
  isBasketLoadedGuard,
  useBasket
} from "./hooks/use-basket";
import { ReactComponent as RemoveIcon } from "./remove.svg";
import { ReactComponent as ImageIcon } from "./image.svg";
import { toast } from "react-toastify";
import sanitize from "sanitize-html";

const Container = styled.div`
  overflow: hidden;
  display: grid;

  @media (min-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

const HeadersList = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.backgroundLighter};
  padding: 8px 8px 16px 8px;
  column-gap: 8px;
  overflow: auto;

  @media (min-width: 576px) {
    padding: 16px;
  }
`;

const ProductHeaderContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.backgroundLighter};
  padding: 16px;
  align-items: center;
  box-shadow: ${(props) =>
    props.selected ? "rgba(0, 0, 0, 0.3) 0px 1px 5px" : "none"};
  cursor: pointer;
  overflow: hidden;
  min-width: 150px;
  border-radius: 5px;
  color: ${(props) =>
    props.selected ? props.theme.textContrastColor : props.theme.textColor};

  transition: transform 0.15s ease-in;

  &:hover {
    color: ${(props) => props.theme.textContrastColor};
    transform: scale(1.02);
    transform-origin: center center;
  }

  img {
    width: 80px;
    height: 100px;
    margin-bottom: 8px;
  }

  span {
    max-width: 180px;
    text-align: center;
    font-size: 12px;
    font-weight: ${(props) => (props.selected ? 700 : 600)};
  }

  @media (min-width: 576px) {
    display: flex;
    min-width: 165px;

    img {
      width: 100px;
      height: 120px;
      margin-bottom: 8px;
    }

    span {
      max-width: 200px;
      text-align: center;
      font-size: 14px;
      font-weight: ${(props) => (props.selected ? 700 : 600)};
    }
  }
`;

const DetailContainer = styled.div`
  overflow: auto;
  width: 100%;
  padding: 16px;

  @media (min-width: 576px) {
    flex: 1;
    align-self: center;

    display: flex;
    column-gap: 25px;
    padding: 0;
  }
`;

const DetailDescription = styled.span`
  flex: 1;

  color: ${(props) => props.theme.textColor};
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;

  @media (min-width: 576px) {
    flex: 1;
    padding: 25px 25px 25px 25px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    min-width: 400px;
  }
`;

const DetailOrdering = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;

  @media (min-width: 576px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 50px 25px 25px 25px;
    min-width: 400px;
  }
`;

const DetailPrices = styled.div`
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.textColor};
  padding: 16px;
  text-align: center;

  @media (min-width: 576px) {
    padding: 16px;
    font-size: 18px;
    text-align: left;
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &:first-of-type {
    align-items: stretch;
  }
`;

const DetailAmount = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.backgroundShadeDarker};

  font-weight: 700;
  font-size: 15px;
  color: ${(props) => props.theme.textColorDarker};
  margin: 8px 0;

  &:first-of-type {
    margin-bottom: 4px;
  }

  span {
    margin: 0 25px;
  }

  svg {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    width: 20px;
    transition: transform 0.3s ease-in;

    &:hover {
      fill: ${(props) => (props.disabled ? "black" : props.theme.primary)};
      transform: ${(props) => (props.disabled ? "none" : "scale(1.2)")};
      transform-origin: center center;
    }
  }
`;

function CzkOrderControl(props: {
  selectedProduct: BasketBranchProduct;
  branchUuid: string;
}) {
  const { selectedProduct, branchUuid } = props;

  const basket = useBasket();
  const hasWriteAccess = useHasWriteAccess();

  if (!isBasketLoadedGuard(basket)) {
    throw new Error(`Basket is not loaded`);
  }

  const removeItem = useCallback(() => {
    if (selectedProduct.amountInCZK === 0) {
      return;
    }

    basket.removeItemFromBasket({
      paymentType: PriceType.CZK,
      productUuid: selectedProduct.uuid,
      branchUuid: branchUuid
    });
  }, [basket, selectedProduct, branchUuid]);

  const addItem = useCallback(() => {
    if (!hasWriteAccess) {
      return;
    }

    basket.addItemToBasket({
      paymentType: PriceType.CZK,
      productUuid: selectedProduct.uuid,
      branchUuid: branchUuid,
      amount: 1
    });
  }, [basket, selectedProduct, branchUuid, hasWriteAccess]);

  return (
    <DetailAmount disabled={!hasWriteAccess}>
      <RemoveIcon onClick={removeItem} />
      <span>{selectedProduct.amountInCZK} x</span>
      <AddIcon onClick={addItem} />
    </DetailAmount>
  );
}

function PtsOrderControl(props: {
  selectedProduct: BasketBranchProduct;
  branchUuid: string;
}) {
  const { selectedProduct, branchUuid } = props;

  const basket = useBasket();
  const hasWriteAccess = useHasWriteAccess();

  if (!isBasketLoadedGuard(basket)) {
    throw new Error(`Basket is not loaded`);
  }

  const removeItem = useCallback(() => {
    if (selectedProduct.amountInPTS === 0) {
      return;
    }

    basket.removeItemFromBasket({
      paymentType: PriceType.PTS,
      productUuid: selectedProduct.uuid,
      branchUuid: branchUuid
    });
  }, [basket, selectedProduct, branchUuid]);

  const addItem = useCallback(() => {
    if (!hasWriteAccess) {
      return;
    }

    if (
      basket.benefitPoints -
        basket.basketSummary.totalBenefitPoints -
        assertExistence(selectedProduct.priceInPTS) <
      0
    ) {
      toast.error("Nemáte dost benefitních bodů");
      return;
    }

    basket.addItemToBasket({
      paymentType: PriceType.PTS,
      productUuid: selectedProduct.uuid,
      branchUuid: branchUuid,
      amount: 1
    });
  }, [basket, selectedProduct, branchUuid, hasWriteAccess]);

  return (
    <DetailAmount disabled={!hasWriteAccess}>
      <RemoveIcon onClick={removeItem} />
      <span>{selectedProduct.amountInPTS} x</span>
      <AddIcon onClick={addItem} />
    </DetailAmount>
  );
}

const Product = (props: {
  uuid: string;
  title: string;
  selected: boolean;
  onClick: () => void;
}) => {
  const { uuid, title, selected, onClick } = props;

  const [img, setImg] = useState<string | null>(null);

  const { getDocumentByRecord } = useApplicationClient();

  useEffect(() => {
    //TODO mount guard
    (async () => {
      const data = await getDocumentByRecord({ recordUuid: uuid });

      if (isResponseSuccess(data)) {
        setImg(data.payload.data);
      }
      //TODO error handling
    })();
  }, [getDocumentByRecord, uuid, title]);

  return (
    //TODO refactor styling
    <ProductHeaderContainer selected={selected} onClick={onClick}>
      {img && <img src={`data:image/jpg;base64,${img}`} alt="" />}
      {!img && (
        <div
          style={{
            display: "flex",
            width: 100,
            height: 120,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ImageIcon style={{ width: 50, height: 50, fill: "#969696" }} />
        </div>
      )}
      <span>{title}</span>
    </ProductHeaderContainer>
  );
};

export function ProductView(props: { branchUuid: string; category: string }) {
  const { branchUuid, category } = props;

  const [selectedProductUuid, setSelectedProductUuid] = useState<string | null>(
    null
  );

  const basket = useBasket();

  if (!isBasketLoadedGuard(basket)) {
    throw new Error(`Dialog can not be used until basket is loaded`);
  }

  const selectedProduct = useMemo(() => {
    return basket.branchProducts[branchUuid].find(
      (item) => item.uuid === selectedProductUuid
    );
  }, [selectedProductUuid, basket, branchUuid]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.category === category) {
      return;
    }

    if (
      isBasketLoadedGuard(basket) &&
      basket.branchProducts[branchUuid].filter(
        (product) => product.category === category
      )[0]
    ) {
      setSelectedProductUuid(
        basket.branchProducts[branchUuid].filter(
          (product) => product.category === category
        )[0].uuid
      );
    } else {
      setSelectedProductUuid(null);
    }
  }, [basket, branchUuid, category, selectedProduct]);

  return (
    <Container>
      <HeadersList>
        {basket.branchProducts[branchUuid]
          .filter((product) => product.category === category)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((item) => (
            <Product
              key={item.uuid}
              title={item.title}
              uuid={item.uuid}
              selected={selectedProduct?.uuid === item.uuid}
              //TODO useCallback
              onClick={() => setSelectedProductUuid(item.uuid)}
            />
          ))}
      </HeadersList>
      {selectedProduct && (
        <DetailContainer>
          <DetailDescription
            dangerouslySetInnerHTML={{
              __html: sanitize(`${selectedProduct.description}`, {})
            }}
          ></DetailDescription>
          <DetailOrdering>
            <VerticalContainer>
              {selectedProduct.priceInCZK !== undefined && (
                <DetailPrices>
                  Cena: {selectedProduct.priceInCZK} Kč
                </DetailPrices>
              )}
              {selectedProduct.priceInPTS !== undefined && (
                <DetailPrices>
                  Benefit: {selectedProduct.priceInPTS} bodů
                </DetailPrices>
              )}
              <DetailPrices style={{ fontSize: 13 }}>
                {selectedProduct.inStock > 0
                  ? "Dostupné skladem"
                  : "Nedostupné"}
              </DetailPrices>
            </VerticalContainer>
            <VerticalContainer>
              {selectedProduct.priceInCZK !== undefined &&
                selectedProduct.inStock > 0 && (
                  <CzkOrderControl
                    selectedProduct={selectedProduct}
                    branchUuid={branchUuid}
                  />
                )}
              {selectedProduct.priceInPTS !== undefined &&
                selectedProduct.inStock > 0 && (
                  <PtsOrderControl
                    selectedProduct={selectedProduct}
                    branchUuid={branchUuid}
                  />
                )}
            </VerticalContainer>
          </DetailOrdering>
        </DetailContainer>
      )}
    </Container>
  );
}
