import { useCallback, useState } from "react";
import styled from "styled-components";
import { useApplicationConfig } from "../hooks/use-application-config";
import { AppPage } from "./app";
import { Header } from "./header";
import { Navigation } from "./navigation";


const AppDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const ContentDiv = styled.div`
  height: 100%;
  overflow: hidden;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const AppShell = () => {
  const [opened, setOpened] = useState(false);

  const config = useApplicationConfig();

  const close = useCallback(() => {
    setOpened(false);
  }, []);

  const open = useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <AppDiv>
      <Navigation onClose={close} opened={opened} version={config.version} />
      <ContentDiv>
        <Header openMenu={open} />
        <AppPage />
      </ContentDiv>
    </AppDiv>
  );
};
