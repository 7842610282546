import { Menu, MenuItem } from "@szhsin/react-menu";
import { useCallback, useMemo } from "react";
import "@szhsin/react-menu/dist/index.css";
import styled from "styled-components";

import { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
import { Search } from "../search/search";
import { useSearchParams } from "react-router-dom";
import { useLogger } from "../../common/use-logger";
import { assertExistence } from "../../../../shared/src/common/assert";

export interface FilterNameWithID {
  id: string;
  name: string;
}

export interface Filter {
  name: string;
  column: string;
  values: FilterNameWithID[];
}

const encodeFilters = (filters: { column: string; values: string[] }[]) => {
  return {
    f: btoa(JSON.stringify(filters))
  };
};

const decodeFilters = (filters: string | null) =>
  JSON.parse(atob(filters || btoa(JSON.stringify([])))) as {
    column: string;
    values: string[];
  }[];

export const useActiveFilters = () => {
  const [searchParams] = useSearchParams();

  return decodeFilters(searchParams.get("f"));
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: scenter;
  row-gap: 12px;
`;

const FilterRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
`;

const LoadingPlaceholder = styled.div`
  background: #e5e5e5;
  height: 35px;
  border-radius: 5px;
  align-self: center;
  width: 165px;
  animation: fade-in 0.2s ease-out;
`;

const FilterChip = styled.div`
  align-self: center;

  border-radius: 16px;
  height: 25px;
  max-width: 100px;
  padding: 0 12px;

  background: ${(props) => props.theme.background};
  font-size: 10px;
  font-weight: 800;
  line-height: 25px;
  color: ${(props) => props.theme.textContrastColor};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  animation: fade-in 0.2s ease-out;
`;

const FilterButton = styled.button<{ active?: boolean }>`
  height: 35px;
  border: none;
  border-radius: 25px;
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: ${(props) =>
    props.active ? props.theme.backgroundLighter : props.theme.textColor};
  font-size: 12px;
  font-weight: 600;

  background-color: ${(props) =>
    props.active ? props.theme.primary : props.theme.backgroundLighter};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;

  transition: transform 0.1s ease-in;

  svg {
    width: 17px;
    height: 17px;
    position: relative;
    left: 7.5px;
    margin-right: 3px;

    fill: ${(props) =>
      props.active ? props.theme.backgroundLighter : props.theme.textColor};
  }
`;

export const FiltersWithSearch = (props: {
  filters: Filter[];
  search: string;
  onSearchChanged: (value: string) => void;
  loading?: boolean;
}) => {
  const { filters, loading, search, onSearchChanged } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const logger = useLogger(`FiltersWithSearch`);

  const onToggleFilterValue = useCallback(
    (filter: string, value: string) => {
      logger.debug(`Toggling filter ${filter}, value ${value}`);
      const activeValues = JSON.parse(
        atob(searchParams.get("f") || btoa(JSON.stringify([])))
      ) as { column: string; values: string[] }[];

      const existingFilter = activeValues.find(
        (item) => item.column === filter
      );

      if (existingFilter) {
        logger.debug(`Filter exists`);
        if (existingFilter.values.includes(value)) {
          logger.debug(`Removing value from filter`);

          existingFilter.values = existingFilter.values.filter(
            (item) => item !== value
          );
        } else {
          logger.debug(`Adding value to filter`);

          existingFilter.values.push(value);
        }
      } else {
        logger.debug(`Adding new filter`);
        activeValues.push({ column: filter, values: [value] });
      }

      setSearchParams(
        encodeFilters(activeValues.filter((filter) => filter.values.length > 0))
      );
    },
    [setSearchParams, searchParams, logger]
  );

  const activeFilters = useMemo(() => {
    const activeValues = decodeFilters(searchParams.get("f"));

    return activeValues.reduce((memo, item) => {
      const filter = filters.find((left) => left.column === item.column);
      memo[item.column] = item.values.map((left: string) => ({
        id: left,
        name: assertExistence(filter?.values.find((a) => a.id === left)).name
      }));
      return memo;
    }, {} as Record<string, { id: string; name: string }[]>);
  }, [searchParams, filters]);

  return (
    <FilterContainer>
      <FilterRowContainer>
        {loading && (
          <>
            <LoadingPlaceholder />
            <LoadingPlaceholder />
          </>
        )}
        {filters.map((filter) => {
          const activeFilter = activeFilters[filter.column];
          return (
            <Menu
              key={filter.name}
              menuButton={
                <FilterButton active={activeFilter && activeFilter?.length > 0}>
                  {filter.name}
                  <ArrowDownIcon />
                </FilterButton>
              }
              align="start"
              offsetY={10}
              viewScroll="initial"
            >
              {filter.values.map((item) => {
                return (
                  <MenuItem
                    style={{ fontSize: 14, paddingRight: 30 }}
                    type="checkbox"
                    key={item.name}
                    checked={
                      !!activeFilter?.find((left) => left.id === item.id)
                    }
                    onClick={(e) => {
                      onToggleFilterValue(filter.column, item.id);
                      e.keepOpen = true;
                    }}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </Menu>
          );
        })}
        <Search
          placeholder="Hledat..."
          value={search}
          onChange={onSearchChanged}
        />
      </FilterRowContainer>
      {Object.values(activeFilters).flat().length > 0 && (
        <FilterRowContainer>
          {Object.values(activeFilters)
            .flat()
            .map((item) => (
              <FilterChip key={item.id}>{item.name}</FilterChip>
            ))}
        </FilterRowContainer>
      )}
    </FilterContainer>
  );
};
