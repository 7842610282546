import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isResponseSuccess } from "../../../shared/src/api/api-contracts";
import { GetLastMaintenancesListItem } from "../../../shared/src/features/devices-feature";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { Button } from "../ui/button/button";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { DashboardSection } from "./dashboard-section";
import { DashboardSectionTitle } from "./dashboard-section-title";


const ListContainer = styled.div`
  flex: 1;
  display: flex;
  min-height: 250px;
  border-bottom: ${(props) => `1px solid ${props.theme.textColorLighter}`};
  margin-bottom: 8px;

  @media (min-width: 576px) {
    margin: 0;
    margin-top: 8px;
    border: none;
  }
`;

export const DashboardMaintenancesSection = () => {
  const api = useApplicationClient();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const response = await api.getLastMaintenances();

    if (isResponseSuccess(response)) {
      return {
        count: response.payload.count,
        rows: response.payload.rows
      };
    }

    return {
      count: 0,
      rows: []
    };
  }, [api]);

  const columns: ListColumn<GetLastMaintenancesListItem>[] = useMemo(
    () => [
      {
        title: "Datum",
        dataIndex: "createdDate",
        render: (data) => (
          <TruncatedText
            id={`${data.uuid}-createdDate`}
          >{`${data.createdDate}`}</TruncatedText>
        ),
        minWidth: 30,
        flex: 1
      },
      {
        title: "Číslo",
        dataIndex: "number",
        render: (data) => (
          <TruncatedText
            id={`${data.uuid}-number`}
          >{`${data.number}`}</TruncatedText>
        ),
        minWidth: 30,
        flex: 1
      },
      {
        title: "",
        dataIndex: "",
        render: (item) => (
          <>
            <Button
              primary
              style={{
                padding: `0 8px`,
                height: 25,
                fontSize: 11
              }}
              onClick={() => {
                navigate(`/devices/${item.deviceUuid}/maintenances`);
              }}
            >
              Zařízení
            </Button>
          </>
        ),
        minWidth: 58,
        flex: 0
      }
    ],
    [navigate]
  );

  return (
    <DashboardSection>
      <DashboardSectionTitle>Poslední servisy</DashboardSectionTitle>

      <ListContainer>
        <InfiniteList
          columns={columns}
          loadRows={fetchData}
          dependencies={[fetchData]}
          withoutShadow
        />
      </ListContainer>
    </DashboardSection>
  );
};
