import { isResponseSuccess } from "@shared/api/api-contracts";
import { DocumentType } from "@shared/domain/document.types";
import { GetDocumentsListItem } from "@shared/features/documents-feature";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useLogger } from "../common/use-logger";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { useAuthState } from "../root/hooks/use-auth-state";
import { FiltersWithSearch, useActiveFilters } from "../ui/filters/filters";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { LoadInfiniteListProps } from "../ui/infinite-list/use-infinite-list";
import { ListHeader } from "../ui/pages/list-header";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { DocumentDownloadRow } from "./document-download-row";

const useFiltersConfig = () => {
  const authState = useAuthState();

  return useMemo(() => {
    return [
      {
        name: "Typ dokumentu",
        column: "types",
        // TODO add all types that should be here
        values: [
          { id: DocumentType.Contract, name: "Smlouva + dodatek" },
          { id: DocumentType.MaintenanceList, name: "Servisní list" },
          { id: DocumentType.Manual, name: "Návod" },
          { id: DocumentType.RevisionList, name: "Revizní list" },
          { id: DocumentType.DeliveryList, name: "Dodací list" },
          { id: DocumentType.Invoice, name: "Doklady" }
        ]
      },
      {
        name: "Typy zařízení",
        column: "deviceTypes",
        values: authState.deviceTypes.map((item) => ({
          id: item.uuid,
          name: item.name
        }))
      },
      {
        name: "Provozovny",
        column: "branches",
        values: authState.branches.map((item) => ({
          id: item.uuid,
          name: item.name
        }))
      }
    ];
  }, [authState]);
};

const useColumns = () => {
  return useMemo(() => {
    const columns: ListColumn<GetDocumentsListItem>[] = [
      {
        title: "Datum",
        dataIndex: "createdDate",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-createdDate`}>
            {moment(data.createdDate).format("DD.MM.YYYY")}
          </TruncatedText>
        ),
        minWidth: 75,
        flex: 1
      },
      {
        title: "Typ",
        dataIndex: "type",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-type`}>{data.type}</TruncatedText>
        ),
        minWidth: 125,
        flex: 1.5
      },
      {
        title: "Popis",
        dataIndex: "title",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-title`}>{data.title}</TruncatedText>
        ),
        minWidth: 200,
        flex: 3
      },
      {
        title: "Provozovna",
        dataIndex: "branchName",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-branchName`}>
            {data.branchName ||
              data.deviceBranchName ||
              data.deviceTypeDeviceBranchesNames.join(", ") ||
              data?.testBranchName}
          </TruncatedText>
        ),
        minWidth: 200,
        flex: 3
      },
      {
        title: "Typ zařízení",
        dataIndex: "deviceTypeName",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-deviceTypeName`}>
            {data.deviceTypeName ||
              data.deviceDeviceTypeName ||
              data?.testTypeName}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1.5
      },
      {
        title: "Zařízení",
        dataIndex: "deviceRegistrationNumber",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-deviceRegistrationNumber`}>
            {data.deviceRegistrationNumber ||
              data.deviceTypeDevicesNames.join(", ") ||
              data?.deviceName}
          </TruncatedText>
        ),
        minWidth: 200,
        flex: 3
      },
      {
        title: "Ke stažení",
        dataIndex: "downloadable",
        render: (data) =>
          data.downloadable && (
            <DocumentDownloadRow documentUuid={data.uuid} type={data.suffix} />
          ),
        minWidth: 70,
        flex: 0
      }
    ];

    return columns;
  }, []);
};

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DocumentsList = () => {
  const { getDocumentsList } = useApplicationClient();
  const activeFilters = useActiveFilters();
  const columns = useColumns();
  const filters = useFiltersConfig();
  const [searchParams, setSearchParams] = useSearchParams();

  const logger = useLogger(`DocumentsList`);

  const setSearch = useCallback(
    (s: string) => {
      setSearchParams({ s });
    },
    [setSearchParams]
  );

  const fetchData = useCallback(
    async (props: LoadInfiniteListProps) => {
      const response = await getDocumentsList({
        startIndex: props.startIndex,
        stopIndex: props.stopIndex,
        search: searchParams.get(`s`) || "",
        sortColumn: props.sortColumn,
        sortOrder: props.sortOrder,
        filters: activeFilters
      });

      if (isResponseSuccess(response)) {
        logger.debug(
          `Fetched documents list data from ${props.startIndex} to ${props.stopIndex}`
        );
        return response.payload;
      } else {
        toast.error("Nastala chyba při načítání dat 🤕");
        logger.error(`There was an error loading documents list`, response);
        return {
          count: 0,
          rows: []
        };
      }
    },
    [searchParams, getDocumentsList, activeFilters, logger]
  );

  return (
    <DevicesPageContext>
      <ListHeader>
        <FiltersWithSearch
          filters={filters}
          search={searchParams.get(`s`) || ""}
          onSearchChanged={setSearch}
        />
      </ListHeader>

      <InfiniteList
        columns={columns}
        loadRows={fetchData}
        dependencies={[fetchData]}
      />
    </DevicesPageContext>
  );
};
