import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  animation: fadeIn 0.9s ease-in 1;
  box-sizing: border-box;

  .loading span {
    display: inline-block;
    vertical-align: middle;
    width: 0.6em;
    height: 0.6em;
    margin: 0.19em;
    background: ${(props) => props.theme.primary};
    border-radius: 0.6em;
    animation: loading 1s infinite alternate;
  }

  .loading span:nth-of-type(2) {
    background: ${(props) => props.theme.backgroundDarker};
    animation-delay: 0.2s;
  }
  .loading span:nth-of-type(3) {
    background: ${(props) => props.theme.textContrastColor};
    animation-delay: 0.4s;
  }
  .loading span:nth-of-type(4) {
    background: ${(props) => props.theme.primary};
    animation-delay: 0.6s;
  }
  .loading span:nth-of-type(5) {
    background: ${(props) => props.theme.backgroundDarker};
    animation-delay: 0.8s;
  }
  .loading span:nth-of-type(6) {
    background: ${(props) => props.theme.textContrastColor};
    animation-delay: 1s;
  }
  .loading span:nth-of-type(7) {
    background: ${(props) => props.theme.primary};
    animation-delay: 1.2s;
  }

  @keyframes loading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Loader = () => {
  return (
    <Container>
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Container>
  );
};
