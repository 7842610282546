import styled from "styled-components";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import { createPortal } from "react-dom";

const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

/**
 * React 18 types so far are not compatible with older ones... until this is fixed, this retyping will work
 */
const RetypedReactTooltip =
  ReactTooltip as unknown as React.FunctionComponent<TooltipProps>;

export const TruncatedText = (props: { children?: string; id: string }) => {
  return (
    <>
      <Text data-tip data-for={props.id}>
        <Text>{props.children}</Text>
      </Text>
      {createPortal(
        <RetypedReactTooltip id={props.id} effect="solid" delayShow={300}>
          <span>{props.children}</span>
        </RetypedReactTooltip>,
        document.getElementById("tooltip") as HTMLDivElement
      )}
    </>
  );
};
