type Success<T> = { payload: T; success: true };

export type ClientError = { success: false; reason: string; code: number };
export type ClientResponse<T> = Success<T> | ClientError;

export const isResponseSuccess = <T>(
  response: Success<T> | ClientError
): response is Success<T> => {
  if ("success" in response && !response.success) {
    return false;
  }

  return true;
};
