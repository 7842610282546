export enum PriceType {
  CZK = "CZK",
  // Products with PriceType.PTS do not have branchUuid and
  // they are common for all branches
  PTS = "PTS",
  Unknown = "Unknown"
}

export enum KnownPriceType {
  CZK = "CZK",
  PTS = "PTS"
}
