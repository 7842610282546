import styled from "styled-components";

export const ListHeader = styled.div<{ compact?: boolean }>`
  padding: ${(props) => (props.compact ? " 0 8px 8px 8px" : "16px")};

  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  justify-content: space-between;

  @media (min-width: 576px) {
    padding: 2px;
    margin-bottom: ${(props) => (props.compact ? "16px" : "25px")};
  }
`;
