import { Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useApplicationConfig } from "../hooks/use-application-config";

export const WithAuth0 = (props: { children: ReactNode }) => {
  const { children } = props;

  const config = useApplicationConfig();

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      audience={config.audience}
      redirectUri={`${window.location.origin}/redirect`}
    >
      {children}
    </Auth0Provider>
  );
};
