import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import { assertExistence } from "@shared/common/assert";
import { GetDevicesDetailResult } from "@shared/features/devices-feature";
import { isResponseSuccess } from "@shared/api/api-contracts";
import joi from "joi";

import { Button } from "../ui/button/button";
import { Loader } from "../ui/loader/loader";
import { useHasWriteAccess } from "../root/hooks/use-auth-state";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { useGlobalDataVersion } from "../root/hooks/use-globa-data-version";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card/card";
import { Text } from "../ui/text/text";
import { Input } from "../ui/forms/input";
import { TextArea } from "../ui/forms/text-area";
import { Select } from "../ui/forms/select";
import { useLogger } from "../common/use-logger";

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    flex: 1;
  }

  &:nth-of-type(2) {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Image = styled.img`
  max-width: 100px;
  margin-bottom: 25px;
`;

//TODO  real types
const options = [
  { value: "Praskla Voda - TODO", label: "Praskla Voda - TODO" },
  { value: "Zanesený senzor - TODO", label: "Zanesený senzor - TODO" }
];

interface FormData {
  date: string;
  description: string;
  contactInfo: string;
  reason: string | null;
}

export const OrderMaintenance = (props: { data: GetDevicesDetailResult }) => {
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();
  const hasWriteAccess = useHasWriteAccess();
  const api = useApplicationClient();
  const { increase } = useGlobalDataVersion();
  const logger = useLogger("OrderMaintenance");

  const onClose = useCallback(() => {
    navigate("../", { replace: true });
  }, [navigate]);

  const save = useCallback(
    async (values: FormData) => {
      setSaving(true);

      const data = {
        orderUuid: v4(),
        reason: assertExistence(values.reason),
        plannedDate: values.date,
        description: values.description,
        deviceUuid: props.data.uuid,
        contactInfo: values.contactInfo
      };

      logger.debug(`Saving data`, data);

      const result = await api.orderMaintenance(data);

      setSaving(false);

      if (isResponseSuccess(result)) {
        logger.debug(`Maintenace was ordered`);
        onClose();
        toast.success("Objednávka byla úspěšně vytvořena 😀", {
          theme: "colored"
        });
        increase();
        return;
      }

      logger.error(`There was an error while ordering maintenace`, result);
      toast.error("Nastala chyba při vytváření servisní objednávky 🤕", {
        theme: "colored",
        hideProgressBar: true
      });
    },
    [onClose, api, props.data.uuid, increase, logger]
  );

  const validate = useCallback((values: FormData) => {
    const errors: Record<string, string> = {};

    if (joi.date().validate(values.date).error) {
      errors["date"] = "Datum je povinné";
    }

    if (joi.date().greater("now").validate(values.date).error) {
      errors["date"] = "Datum musí být v budoucnosti";
    }

    if (joi.string().min(3).validate(values.description).error) {
      errors["description"] = "Popis je moc krátký";
    }

    if (joi.string().validate(values.reason).error) {
      errors["reason"] = "Typ závady je povinný";
    }

    return errors;
  }, []);

  const initialValues: FormData = useMemo(
    () => ({
      date: "",
      reason: null,
      description: "",
      contactInfo: ""
    }),
    []
  );

  useEffect(() => {
    if (!hasWriteAccess) {
      onClose();
    }
  }, [hasWriteAccess, onClose]);

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={save}>
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched
      }) => {
        return (
          <Card wide>
            <Form>
              <CardHeader>
                Objednat Servis - {props.data.serialNumber}
              </CardHeader>

              {saving && <Loader />}
              {!saving && (
                <>
                  <CardContent>
                    <InfoContainer>
                      <InfoColumn>
                        <Text compact>
                          <strong>Typ Zařízení:</strong>
                          <span>{props.data.type}</span>
                        </Text>
                        <Text compact>
                          <strong>Popis:</strong>
                          {props.data.description}
                        </Text>
                        <Text compact style={{ marginBottom: 16 }}>
                          <strong>Umístění:</strong>
                          <span>{props.data.branchName}</span>
                        </Text>
                      </InfoColumn>
                      {props.data.image && (
                        <InfoColumn>
                          <Image
                            src={`data:image/jpg;base64,${props.data.image}`}
                          />
                        </InfoColumn>
                      )}{" "}
                    </InfoContainer>

                    <Input
                      name="date"
                      placeholder="Navrhované datum"
                      type="datetime-local"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.date}
                      error={errors.date}
                      touched={touched.date}
                    />
                    <Select
                      error={errors.reason}
                      touched={touched.reason}
                      placeholder="Typ závady"
                      options={options}
                      onBlur={handleBlur}
                      onChange={(option) =>
                        setFieldValue("reason", option?.value)
                      }
                      value={
                        options
                          ? options.find(
                              (option) => option.value === values.reason
                            )
                          : null
                      }
                      getOptionLabel={(item) => `${item?.label}`}
                      getOptionValue={(item) => `${item?.value}`}
                    />
                    <TextArea
                      name="description"
                      placeholder="Popis závady"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      rows={4}
                      error={errors.description}
                      touched={touched.description}
                    />
                    <Input
                      name="contactInfo"
                      placeholder="Kontaktní osoba, telefon"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactInfo}
                    />
                  </CardContent>
                  <CardFooter>
                    <Button type="button" onClick={onClose}>
                      Zrušit
                    </Button>
                    <Button primary type="submit">
                      Objednat
                    </Button>
                  </CardFooter>
                </>
              )}
            </Form>
          </Card>
        );
      }}
    </Formik>
  );
};
