import { createContext, useContext } from "react";
import { UserPermission } from "@shared/domain/user-types";

export interface AuthState {
  name: string;
  email: string;
  activated: boolean;
  accessLevel: string;
  deviceUuids: string[];
  deviceTypes: { uuid: string; name: string }[];
  branches: { uuid: string; name: string }[];
  mainBranchUuid: string;
}

export const AuthstateContext = createContext<AuthState | null>(null);

export const useAuthState = () => {
  const context = useContext(AuthstateContext);

  if (!context) {
    throw new Error(`Can not use auth state outside its context`);
  }

  return context;
};

export const useHasWriteAccess = () => {
  const authState = useAuthState();

  return authState.accessLevel === UserPermission.Write;
};
