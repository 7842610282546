import { useCallback } from "react";
import styled from "styled-components";

import { ListColumn, Sort, SortEnum } from "./infinite-list";
import { InfiniteListColumn } from "./list-column";

import { ReactComponent as ArrowDropUpIcon } from "./arrow-drop-up.svg";

const RowContainer = styled.div`
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 16px;
  background: ${(props) => props.theme.backgroundShadeDarker};
  border-radius: 0px;
  color: black;
  font-weight: 600;
  font-size: 12px;
  min-width: min-content;
  border-radius: 2px 2px 0px 0px;
  user-select: none;
  box-sizing: border-box;
`;

export const InfiniteListHeaderRow = <Record,>(props: {
  columns: ListColumn<Record>[];
  onSort: (column: ListColumn<Record>) => void;
  scrollShowed: boolean;
  width: number;
  sort?: Sort<Record>;
}) => {
  const { columns, sort, onSort, scrollShowed, width } = props;

  const renderSortOrder = useCallback((order: SortEnum) => {
    switch (order) {
      case SortEnum.ASC:
        return <ArrowDropUpIcon />;
      case SortEnum.DES:
        return <ArrowDropUpIcon style={{ transform: "rotate(180deg)" }} />;
      case SortEnum.NONE:
        return <></>;
      default:
        throw new Error("Invalid state");
    }
  }, []);

  return (
    <RowContainer style={{ paddingRight: scrollShowed ? 16 : 20, width }}>
      {columns.map((item) => {
        return (
          <InfiniteListColumn
            key={item.title}
            style={{
              minWidth: item.minWidth,
              flex: item.flex,
              cursor: item.sortable ? "pointer" : "default",
            }}
            onClick={() => onSort(item)}
          >
            {item.title}
            {sort && sort.col === item && renderSortOrder(sort.order)}
          </InfiniteListColumn>
        );
      })}
    </RowContainer>
  );
};
