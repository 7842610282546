export enum UserPermission {
  Write = "WRITE",
  Read = "READ"
}

export interface User {
  uuid: string;
  email: string;
  name: string;
}

export enum UserStatus {
  CREATED = "CREATED",
  ACTIVATED = "ACTIVATED"
}
