import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { WithGlobalAppModal } from "../../ui/modal/global-modal";
import { AppLoading } from "./app-loading";
import { AppShell } from "./app-shell";
import { ApplicationTheme, GlobalStyles } from "./global-styles";
import { ResetPassword } from "./reset-password";
import { WithApplicationClient } from "./with-application-client";
import { WithApplicationConfig } from "./with-application-config";
import { WithAuth0 } from "./with-auth0";
import { WithAuthenticatedApp } from "./with-authenticated-app";
import { WithAuthorizedApp } from "./with-authorized-app";
import { WithGlobalDataVersion } from "./with-global-data-version";

const Redirect = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return <AppLoading />;
};

export const App = () => {
  return (
    <WithApplicationConfig>
      <ThemeProvider theme={ApplicationTheme}>
        <GlobalStyles />
        <ToastContainer autoClose={3000} limit={5} position={"bottom-left"} />
        <div id="modal" />
        <div id="tooltip" />

        <BrowserRouter>
          <Routes>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            <Route
              path="/*"
              element={
                <WithAuth0>
                  <WithGlobalAppModal>
                    <WithAuthenticatedApp>
                      <WithApplicationClient>
                        <WithAuthorizedApp>
                          <WithGlobalDataVersion>
                            <AppShell />
                          </WithGlobalDataVersion>
                        </WithAuthorizedApp>
                      </WithApplicationClient>
                    </WithAuthenticatedApp>
                  </WithGlobalAppModal>
                </WithAuth0>
              }
            />
            <Route
              path="/redirect"
              element={
                <WithAuth0>
                  <Redirect />
                </WithAuth0>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </WithApplicationConfig>
  );
};
