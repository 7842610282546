import styled from "styled-components";

export const ListRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: $text-color;
  height: calc(100% - 0px);
  background: ${(props) => props.theme.backgroundLighter};
  padding: 0 16px;
  cursor: pointer;
  align-items: stretch;
  align-self: stretch;
  border-bottom: 1px solid #d6d6d6;
  animation: fadeIn 0.1s ease-in 1;
  box-sizing: border-box;

  &.odd {
    background: ${(props) => props.theme.backgroundShade};
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
