import React from "react";
import ReactDOM from "react-dom";

import { App } from "./root/components/root";
import { assertExistence } from "@shared/common/assert";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  assertExistence(document.getElementById("root"))
);
