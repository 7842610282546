import { isResponseSuccess } from "@shared/api/api-contracts";
import { GetOrderedItemsListItem } from "@shared/features/eshop-feature";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useLogger } from "../common/use-logger";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { useAuthState, useHasWriteAccess } from "../root/hooks/use-auth-state";
import { Button } from "../ui/button/button";
import { FiltersWithSearch, useActiveFilters } from "../ui/filters/filters";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { LoadInfiniteListProps } from "../ui/infinite-list/use-infinite-list";
import { ListHeader } from "../ui/pages/list-header";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { isBasketLoadedGuard, useBasket } from "./hooks/use-basket";

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const useFiltersConfig = () => {
  const authState = useAuthState();

  return useMemo(() => {
    return [
      {
        name: "Provozovny",
        column: "branchUuid",
        values: authState.branches.map((item) => ({
          id: item.uuid,
          name: item.name
        }))
      }
    ];
  }, [authState]);
};

const useColumns = () => {
  const basket = useBasket();
  const hasWriteAccess = useHasWriteAccess();

  return useMemo(() => {
    const columns: ListColumn<GetOrderedItemsListItem>[] = [
      {
        title: "Datum objednávky",
        dataIndex: "createdAt",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-createdAt`}>
            {moment(data.createdAt).format("DD.MM.YYYY")}
          </TruncatedText>
        ),
        minWidth: 150,
        flex: 2
      },
      {
        title: "Provozovny",
        dataIndex: "branchName",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-branchName`}>
            {data.branchName}
          </TruncatedText>
        ),
        minWidth: 150,
        flex: 3
      },
      {
        title: "Položka",
        dataIndex: "productName",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-productName`}>
            {data.productName}
          </TruncatedText>
        ),
        minWidth: 250,
        flex: 3
      },
      {
        title: "Množství",
        dataIndex: "amount",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-amount`}>
            {`${data.amount}x`}
          </TruncatedText>
        ),
        minWidth: 50,
        flex: 3
      },
      {
        title: "Skladem",
        dataIndex: "inStock",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-inStock`}>
            {data.inStock ? "Ano" : "Ne"}
          </TruncatedText>
        ),
        minWidth: 150,
        flex: 3
      },

      {
        title: "",
        dataIndex: "",
        render: (item) => (
          <Button
            primary
            style={{
              //TODO unify list buttons
              padding: `0 8px`,
              height: 25,
              fontSize: 11
            }}
            disabled={!hasWriteAccess}
            onClick={() => {
              //TODO check if it can be added
              // - does product exist??
              // - are there points available??
              // - is product in stock??
              if (isBasketLoadedGuard(basket)) {
                basket.addItemToBasket({
                  productUuid: item.productUuid,
                  branchUuid: item.branchUuid,
                  paymentType: item.paymentType,
                  amount: item.amount
                });
              }
            }}
          >
            Přidat
          </Button>
        ),
        minWidth: 70,
        flex: 0
      }
    ];

    return columns;
  }, [basket, hasWriteAccess]);
};

export function OrderHistory() {
  const [search, setSearch] = useState("");

  const { getOrderedItemsList } = useApplicationClient();
  const activeFilters = useActiveFilters();
  const columns = useColumns();
  const filters = useFiltersConfig();
  const logger = useLogger("OrderHistory");

  const fetchData = useCallback(
    async (props: LoadInfiniteListProps) => {
      const response = await getOrderedItemsList({
        startIndex: props.startIndex,
        stopIndex: props.stopIndex,
        search,
        sortColumn: props.sortColumn,
        sortOrder: props.sortOrder,
        filters: activeFilters
      });

      if (isResponseSuccess(response)) {
        logger.debug(
          `Loaded order history list data from ${props.startIndex} to ${props.stopIndex}`
        );
        return response.payload;
      } else {
        toast.error("Nastala chyba při načítání dat 🤕");
        logger.error(`There was an error loading list`, response);
        return {
          count: 0,
          rows: []
        };
      }
    },
    [search, activeFilters, getOrderedItemsList, logger]
  );

  return (
    <DevicesPageContext>
      <ListHeader>
        <FiltersWithSearch
          filters={filters}
          search={search}
          onSearchChanged={setSearch}
        />
      </ListHeader>

      <InfiniteList
        columns={columns}
        loadRows={fetchData}
        dependencies={[fetchData]}
      />
    </DevicesPageContext>
  );
}
