import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { PriceType } from "@shared/domain/products.types";
import Joi from "joi";
import { Form, Formik } from "formik";

import { Button } from "../ui/button/button";
import { Input } from "../ui/forms/input";
import { TextArea } from "../ui/forms/text-area";
import { isBasketLoadedGuard, useBasket } from "./hooks/use-basket";
import { BranchItem, TransportItem } from "./basket-summary-item";
import { BranchTitle } from "./basket-summary-title";
import { useHasWriteAccess } from "../root/hooks/use-auth-state";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 8px;
  padding: 16px;
  margin-bottom: 25px;

  background: ${(props) => props.theme.backgroundShade};
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: ${(props) => props.theme.textContrastColor};
`;

const ItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TotalPriceContainer = styled.span`
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.textContrastColor};
`;

const TotalPriceTitle = styled.h4`
  margin: 0;
  flex: 1;
`;

const TotalPriceAmount = styled.span`
  min-width: 160px;
`;

function TotalPrice(props: { totalPrice: number }) {
  const { totalPrice } = props;

  return (
    <TotalPriceContainer>
      <TotalPriceTitle>Celkem</TotalPriceTitle>
      <TotalPriceAmount>{totalPrice.toLocaleString()} Kč</TotalPriceAmount>
    </TotalPriceContainer>
  );
}

const BenefitPointsSummaryContainer = styled.span`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  color: ${(props) => props.theme.textColor};
`;

const BenefitPointsSummaryTitle = styled.h4`
  flex: 1;
`;

const BenefitPointsSummaryAmount = styled.span`
  min-width: 160px;
  font-size: 15px;
  font-weight: 500;
`;

const BenefitPointsSummaryAfter = styled.span`
  font-size: 13px;
  font-weight: 500;
`;

function BenefitPointsSummary(props: { total: number; after: number }) {
  const { total, after } = props;

  return (
    <BenefitPointsSummaryContainer>
      <BenefitPointsSummaryTitle>
        Stav věrnostního programu
      </BenefitPointsSummaryTitle>
      <BenefitPointsSummaryAmount>
        {`${total} `}
        <BenefitPointsSummaryAfter>
          (po uplatnění {after})
        </BenefitPointsSummaryAfter>
      </BenefitPointsSummaryAmount>
    </BenefitPointsSummaryContainer>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  column-gap: 16px;
`;

interface FormData {
  note: string;
  orderNumber: string;
}

function BasketSubmitForm() {
  const basket = useBasket();
  const hasWriteAccess = useHasWriteAccess();

  const validate = useCallback((values: FormData) => {
    const errors: Record<string, string> = {};

    if (Joi.string().min(3).validate(values.orderNumber).error) {
      errors["description"] = "Číslo objednávky je moc krátké";
    }

    return errors;
  }, []);

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (!hasWriteAccess) {
        return;
      }

      if (!isBasketLoadedGuard(basket)) {
        throw new Error(`Can not submit unloaded basket`);
      }

      await basket.submitBasket(values);
    },
    [basket, hasWriteAccess]
  );

  const resetBasket = useCallback(async () => {
    if (!hasWriteAccess) {
      return;
    }

    if (!isBasketLoadedGuard(basket)) {
      throw new Error(`Can not submit unloaded basket`);
    }

    await basket.resetBasket();
  }, [basket, hasWriteAccess]);

  const initialValues: FormData = useMemo(() => {
    return {
      note: "",
      orderNumber: ""
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => {
        return (
          <Form>
            <TextArea
              name="note"
              placeholder="Poznámka k objednávce"
              rows={4}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
              error={errors.note}
              touched={touched.note}
            />
            <Input
              name="orderNumber"
              placeholder="Objednávkové číslo zákazníka"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orderNumber}
              error={errors.orderNumber}
              touched={touched.orderNumber}
            />
            <Actions>
              <Button
                type="button"
                disabled={!hasWriteAccess}
                onClick={resetBasket}
              >
                Smazat košík
              </Button>
              <Button primary type="submit" disabled={!hasWriteAccess}>
                Objednat
              </Button>
            </Actions>
          </Form>
        );
      }}
    </Formik>
  );
}

export function BasketOrderSummary() {
  const basket = useBasket();

  if (!isBasketLoadedGuard(basket)) {
    return <></>;
  }

  return (
    <Container>
      <Title>Vybrané položky</Title>

      <ItemsContainer>
        {Object.values(basket.basketSummary.branchItems).map((branch) => (
          <React.Fragment key={branch.name}>
            <BranchTitle key={branch.name}>{branch.name}</BranchTitle>
            {branch.items.map((branchItem) => (
              <BranchItem
                key={`${branchItem.title}${branchItem.priceType}`}
                amount={branchItem.amount}
                title={branchItem.title}
                totalPrice={branchItem.totalPrice}
                type={branchItem.priceType}
                branchUuid={branch.uuid}
                productUuid={branchItem.productUuid}
              />
            ))}
          </React.Fragment>
        ))}

        {/* {basket.basketSummary.isThereOrder && (
          <>
            <BranchTitle>Hrazeno z věrnostního programu</BranchTitle>
            <BranchItem
              amount={basket.basketSummary.totalBenefitPoints}
              title={`Benefitní bod`}
              totalPrice={0}
              type={PriceType.CZK}
            />
          </>
        )} */}
        {basket.basketSummary.isThereOrder && (
          <>
            <BranchTitle>Doprava</BranchTitle>
            <TransportItem
              amount={basket.basketSummary.transportAmount}
              title={`Doprava (nad ${basket.basketSummary.transportationPriceLimit} Kč zdarma)`}
              totalPrice={basket.basketSummary.transportFee}
              type={PriceType.CZK}
            />
          </>
        )}
      </ItemsContainer>

      <TotalPrice totalPrice={basket.basketSummary.totalPrice} />
      <BenefitPointsSummary
        total={basket.benefitPoints}
        after={basket.benefitPoints - basket.basketSummary.totalBenefitPoints}
      />
      <BasketSubmitForm />
    </Container>
  );
}
