import { useCallback, useEffect, useState } from "react";
import {
  AutoSizer,
  InfiniteLoader,
  List,
  ListProps,
  InfiniteLoaderProps,
  AutoSizerProps
} from "react-virtualized";
import cx from "classnames";

import { LoadInfiniteList, useInfiniteList } from "./use-infinite-list";
import { InfiniteListHeaderRow } from "./header-row";
import { InfiniteListColumn } from "./list-column";
import { ListRowDiv } from "./list-row";
import { NoData } from "../no-data/no-data";
import { Loader } from "../loader/loader";
import { ListBody } from "./list-body";

export interface ListColumn<Row> {
  title: string;
  dataIndex: string;
  minWidth: number;
  flex: number;
  sortable?: boolean;
  render: (row: Row) => React.ReactNode;
}

export enum SortEnum {
  ASC = "ASC",
  DES = "DESC",
  NONE = ""
}

export interface Sort<Type> {
  col: ListColumn<Type>;
  order: SortEnum;
}

const ROW_HEIGHT = 50;
const HEADER_ROW_HEIGHT = 45;

const rotateSortOrder = (sortValue: SortEnum | undefined): SortEnum => {
  switch (sortValue) {
    case SortEnum.DES:
      return SortEnum.ASC;
    case SortEnum.ASC:
      return SortEnum.NONE;
    case SortEnum.NONE:
      return SortEnum.DES;
    default:
      return SortEnum.DES;
  }
};

const ListRow = <Type extends Record<string, any>>(props: {
  columns: ListColumn<Type>[];
  data: Type;
  index: number;
  onClick: (e: React.MouseEvent) => void;
}) => {
  const { data, index, columns, onClick } = props;

  return (
    <ListRowDiv onClick={onClick} className={cx({ odd: !!(index % 2) })}>
      {columns.map(({ title, minWidth, flex, render }) => {
        return (
          <InfiniteListColumn key={title} style={{ minWidth, flex }}>
            {render(data)}
          </InfiniteListColumn>
        );
      })}
    </ListRowDiv>
  );
};

const ListLoadingRow = <Record,>(props: { columns: ListColumn<Record>[] }) => {
  const { columns } = props;
  return (
    <ListRowDiv>
      {columns.map(({ title, minWidth, flex }) => {
        return <InfiniteListColumn key={title} style={{ minWidth, flex }} />;
      })}
    </ListRowDiv>
  );
};

/**
 * React 18 types so far are not compatible with older ones... until this is fixed, this retyping will work
 */
const RetypedInfiniteList =
  InfiniteLoader as unknown as React.FunctionComponent<InfiniteLoaderProps>;

/**
 * React 18 types so far are not compatible with older ones... until this is fixed, this retyping will work
 */
const RetypedList = List as unknown as React.FunctionComponent<ListProps>;

/**
 * React 18 types so far are not compatible with older ones... until this is fixed, this retyping will work
 */
const RetypedAutoSizer =
  AutoSizer as unknown as React.FunctionComponent<AutoSizerProps>;

export const InfiniteList = <Type extends Record<string, any>>(props: {
  columns: ListColumn<Type>[];
  loadRows: LoadInfiniteList<Type>;
  dependencies: unknown[];
  onRowClick?: (e: React.MouseEvent, row: Type) => void;
  withoutShadow?: boolean;
}) => {
  const { columns, loadRows, onRowClick, dependencies, withoutShadow } = props;

  const [localColumns, setLocalColumns] = useState<ListColumn<Type>[]>([]);
  const [sort, setSort] = useState<Sort<Type> | undefined>();

  const { count, records, isRecordLoaded, loadMoreRows, loadingKey, loading } =
    useInfiniteList(loadRows, dependencies, sort);

  const rowRenderer = useCallback(
    ({ key, index, style }: { key: string; index: number; style: object }) => {
      return (
        <div
          key={key}
          style={{
            ...style
          }}
        >
          {!records[index] ? (
            <ListLoadingRow columns={localColumns} />
          ) : (
            <ListRow
              index={index}
              data={records[index]}
              columns={localColumns}
              onClick={(e: React.MouseEvent) =>
                onRowClick && onRowClick(e, records[index])
              }
            />
          )}
        </div>
      );
    },
    [records, localColumns, onRowClick]
  );

  const changeSort = useCallback(
    (col: ListColumn<Type>): void => {
      const isSortable = !!col.sortable;
      if (!isSortable) {
        return;
      }

      const isAlreadySorted = sort && sort.col === col;

      const newSort =
        isAlreadySorted && sort
          ? {
              col,
              order: rotateSortOrder(sort && sort.order)
            }
          : { col, order: SortEnum.DES };
      setSort(newSort);
    },
    [sort]
  );

  useEffect(() => {
    setLocalColumns(columns);
  }, [columns]);

  const totalWidth =
    localColumns.reduce((memo, item) => memo + item.minWidth, 0) + 155;

  return (
    <ListBody withoutShadow={withoutShadow}>
      <RetypedAutoSizer>
        {({ height, width }) => (
          <>
            <InfiniteListHeaderRow
              columns={localColumns}
              onSort={changeSort}
              sort={sort}
              scrollShowed={
                !loading && count * ROW_HEIGHT > height - HEADER_ROW_HEIGHT
              }
              width={totalWidth > width ? totalWidth : width}
            />
            <RetypedInfiniteList
              key={loadingKey}
              isRowLoaded={({ index }) => isRecordLoaded(index)}
              rowCount={count}
              loadMoreRows={loadMoreRows}
              minimumBatchSize={100}
            >
              {({ onRowsRendered, registerChild }) => (
                <>
                  {!!count && (
                    <div style={{ display: loading ? "none" : "block" }}>
                      <RetypedList
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        rowRenderer={rowRenderer}
                        height={height - HEADER_ROW_HEIGHT}
                        rowCount={count}
                        rowHeight={ROW_HEIGHT}
                        width={totalWidth > width ? totalWidth : width}
                      />
                    </div>
                  )}
                  {!count && !loading && (
                    <div
                      style={{
                        width: totalWidth > width ? totalWidth : width,
                        height: height - HEADER_ROW_HEIGHT,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "white"
                      }}
                    >
                      <NoData />
                    </div>
                  )}
                  {loading && (
                    <div
                      style={{
                        width: totalWidth > width ? totalWidth : width,
                        height: height - HEADER_ROW_HEIGHT,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "white"
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </>
              )}
            </RetypedInfiniteList>
          </>
        )}
      </RetypedAutoSizer>
    </ListBody>
  );
};
