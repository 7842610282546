import { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { useMatch, useNavigate } from "react-router-dom";

import logo from "./logo.png";
import { ReactComponent as DeviceIcon } from "./coffee_maker.svg";
import { ReactComponent as HomeIcon } from "./home.svg";
import { ReactComponent as FolderIcon } from "./folder.svg";
import { ReactComponent as CartIcon } from "./cart.svg";
import { AppVersionTag } from "./app-version-tag";

const StyledNav = styled.nav<{ opened: boolean }>`
  position: fixed;
  width: 270px;
  height: 100%;
  z-index: 20;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: white;

  transition: 0.2s ease-out;
  transform: ${(props) =>
    props.opened ? "translateX(0%)" : "translateX(-100%)"};
  box-shadow: #0000004f 0px 0px 10px -2px;

  @media (min-width: 576px) {
    position: relative;
    transform: none;
  }
`;

const NavHeader = styled.div`
  box-sizing: border-box;
  height: 92px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.backgroundShade};

  span {
    display: none;
  }

  @media (min-width: 576px) {
    position: relative;
    height: 182px;

    span {
      margin-top: 16px;
      display: inline-flex;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 28px;
      color: ${(props) => props.theme.textColor};
    }
  }
`;

const LogoImage = styled.img`
  height: 60px;

  @media (min-width: 576px) {
    height: 65px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 19;

  background: ${(props) => props.theme.overlay};

  @media (min-width: 576px) {
  }
`;

const StyledList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledListItem = styled.li<{ active?: boolean }>`
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: ${(props) => (props.active ? 800 : 600)};
  color: ${(props) => (props.active ? "#e14e12" : "#5a5a5a")};
  box-sizing: border-box;

  span {
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 20px;
      margin-right: 20px;
      fill: ${(props) => (props.active ? "#e14e12" : "#5a5a5a")};
    }
  }

  &:hover {
    background: ${(props) => props.theme.backgroundShade};
  }

  @media (min-width: 576px) {
    span {
      justify-content: flex-start;
    }
  }
`;

const NavigationItem = (props: {
  path: string;
  title: string;
  icon: ReactNode;
  onClick: () => void;
}) => {
  const { path, title, onClick, icon } = props;

  const match = useMatch(`${path}/*`);

  const navigate = useNavigate();
  const navigateTo = useCallback(() => {
    navigate(path);
    onClick();
  }, [navigate, path, onClick]);

  return (
    <StyledListItem onClick={navigateTo} active={!!match}>
      <span>
        {icon}
        {title}
      </span>
    </StyledListItem>
  );
};

export const Navigation = (props: {
  opened: boolean;
  onClose: () => void;
  version: string;
}) => {
  const { opened, onClose, version } = props;

  return (
    <>
      {opened && <Overlay onClick={onClose} />}
      <StyledNav opened={opened}>
        <NavHeader>
          <LogoImage src={logo} alt="Logo" />
          <span>
            Klientská zóna <br /> COFFEE EXPERTS CZE a.s.
          </span>
        </NavHeader>

        <StyledList>
          <NavigationItem
            path="/"
            title="Úvod"
            onClick={onClose}
            icon={<HomeIcon />}
          />
          <NavigationItem
            path="/devices"
            title="Zařízení"
            onClick={onClose}
            icon={<DeviceIcon />}
          />
          <NavigationItem
            path="/eshop"
            title="E-shop"
            onClick={onClose}
            icon={<CartIcon />}
          />
          <NavigationItem
            path="/documents"
            title="Dokumenty"
            onClick={onClose}
            icon={<FolderIcon />}
          />
        </StyledList>
        <AppVersionTag version={version} />
      </StyledNav>
    </>
  );
};
