import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BranchItem } from "../eshop/basket-summary-item";
import { BranchTitle } from "../eshop/basket-summary-title";
import { isBasketLoadedGuard, useBasket } from "../eshop/hooks/use-basket";
import { Button } from "../ui/button/button";
import { Loader } from "../ui/loader/loader";
import { DashboardSection } from "./dashboard-section";
import { DashboardSectionTitle } from "./dashboard-section-title";

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  column-gap: 16px;
`;

export const DashboardEshopSection = () => {
  const basket = useBasket();
  const navigate = useNavigate();

  const goToEshop = useCallback(() => {
    navigate("./eshop");
  }, [navigate]);

  if (!isBasketLoadedGuard(basket)) {
    return <Loader />;
  }

  return (
    <>
      <DashboardSection style={{ flex: 0 }}>
        <DashboardSectionTitle>Věrnostní program</DashboardSectionTitle>
        <BranchTitle>
          Máte k dispozici {basket.benefitPoints} bodů
        </BranchTitle>
      </DashboardSection>
      <DashboardSection>
        <DashboardSectionTitle>Košík</DashboardSectionTitle>

        {Object.values(basket.basketSummary.branchItems).length === 0 && (
          <BranchTitle>Váš Košík je prázdný</BranchTitle>
        )}
        {Object.values(basket.basketSummary.branchItems).map((branch) => (
          <React.Fragment key={branch.name}>
            <BranchTitle key={branch.name}>{branch.name}</BranchTitle>
            {branch.items.map((branchItem) => (
              <BranchItem
                key={`${branchItem.title}${branchItem.priceType}`}
                amount={branchItem.amount}
                title={branchItem.title}
                totalPrice={branchItem.totalPrice}
                type={branchItem.priceType}
              />
            ))}
          </React.Fragment>
        ))}
        <div style={{ flex: 1 }}></div>
        <Actions>
          <Button primary type="submit" onClick={goToEshop}>
            Do košíku
          </Button>
        </Actions>
      </DashboardSection>
    </>
  );
};
