import styled from "styled-components";

export const DetailCard = styled.div`
  overflow: hidden;
  border-radius: 5px;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  color: ${(props) => props.theme.textColor};
  font-size: 14px;
  font-weight: 600;

  background: ${(props) => props.theme.backgroundShade};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  transition: all 0.15s ease-in;
  transform-origin: center;
`;

export const CardHeader = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media (min-width: 576px) {
    padding: 0;
    margin: 25px 25px 8px 25px;
  }
`;

export const CardTitle = styled.h2`
  margin: 0;

  color: ${(props) => props.theme.textContrastColor};
  font-size: 18px;
`;
