import { ClientResponse } from "@shared/api/api-contracts";
import { BasketState } from "@shared/domain/basket";
import {
  GetContactInfoProps,
  GetContactInfoResult,
  GetDevicesDetailProps,
  GetDevicesDetailResult,
  GetDevicesListProps,
  GetDevicesListResult,
  GetLastMaintenancesListResult,
  GetMaintenancesListProps,
  GetMaintenancesListResult,
  OrderMaintenanceProps
} from "@shared/features/devices-feature";
import {
  GetDeviceDocumentsListProps,
  GetDeviceDocumentsListResult,
  GetDocumentByRecordProps,
  GetDocumentProps,
  GetDocumentResult,
  GetDocumentsListProps,
  GetDocumentsListResult
} from "@shared/features/documents-feature";
import {
  AddItemToBasketProps,
  GetOrderedItemsListProps,
  GetOrderedItemsListResult,
  RemoveItemFromBasketProps,
  RemoveItemTypeFromBasketProps,
  RequestUsersBasketProps,
  SubmitBasketProps
} from "@shared/features/eshop-feature";
import { GetWhoAmIResult } from "@shared/features/user-management-feature";
import qs from "qs";
import { createContext, useContext } from "react";

export const buildApplicationClient = (
  getToken: () => Promise<string>,
  logError: (message: string, ...args: unknown[]) => void
) => {
  const sendRequest = async <
    Result = unknown,
    Body = Record<never, never>
  >(config: {
    url: string;
    method: "GET" | "POST";
    body?: Body;
    headers?: HeadersInit;
  }): Promise<ClientResponse<Result>> => {
    try {
      const token = await getToken();

      const result = await fetch(config.url, {
        body: JSON.stringify(config.body),
        method: config.method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ...config.headers
        }
      });

      const { data } = await result.json();

      if (!result.ok) {
        return {
          success: false,
          reason: result.statusText,
          code: result.status
        };
      }

      return { payload: data, success: true };
    } catch (e) {
      logError(`Error handling request`, e);
      return {
        success: false,
        reason: "Unknown error",
        code: 500
      };
    }
  };

  const getWhoAmI = () =>
    sendRequest<GetWhoAmIResult>({ method: "GET", url: "/api/who-am-i" });

  const activateUser = () =>
    sendRequest<void>({
      method: "POST",
      url: "/api/users/activate"
    });

  const getDevices = (props: Omit<GetDevicesListProps, "deviceUuids">) =>
    sendRequest<GetDevicesListResult>({
      method: "GET",
      url: `/api/devices?${qs.stringify({
        ...props,
        filters: JSON.stringify(props.filters)
      })}`
    });

  const getDetail = (props: GetDevicesDetailProps) =>
    sendRequest<GetDevicesDetailResult>({
      method: "GET",
      url: `/api/devices/${props.uuid}`
    });

  const orderMaintenance = (props: OrderMaintenanceProps) =>
    sendRequest<void>({
      method: "POST",
      url: `/api/devices/${props.deviceUuid}/maintenance`,
      body: props
    });

  const getMaintenances = (props: GetMaintenancesListProps) =>
    sendRequest<GetMaintenancesListResult>({
      method: "GET",
      url: `/api/devices/${props.deviceUuid}/maintenances?${qs.stringify({
        ...props,
        filters: JSON.stringify(props.filters)
      })}`
    });

  const getDeviceDocumentsList = async (props: GetDeviceDocumentsListProps) =>
    sendRequest<GetDeviceDocumentsListResult>({
      method: "GET",
      url: `/api/documents/device/${props.deviceUuid}?${qs.stringify({
        ...props,
        filters: JSON.stringify(props.filters)
      })}`
    });

  const getDocument = async (props: GetDocumentProps) =>
    sendRequest<GetDocumentResult>({
      method: "GET",
      url: `/api/documents/${props.uuid}`
    });

  const getDocumentByRecord = async (props: GetDocumentByRecordProps) =>
    sendRequest<GetDocumentResult>({
      method: "GET",
      url: `/api/documents/record/${props.recordUuid}`
    });

  const getDocumentsList = async (
    props: Omit<
      GetDocumentsListProps,
      | "userUuid"
      | "allowedDevices"
      | "allowedDeviceTypeUuids"
      | "allowedBranchUuids"
      | "mainBranchUuid"
    >
  ) =>
    sendRequest<GetDocumentsListResult>({
      method: "GET",
      url: `/api/documents?${qs.stringify({
        ...props,
        filters: JSON.stringify(props.filters)
      })}`
    });

  const requestUsersBasket = async (
    props: Omit<RequestUsersBasketProps, "userUuid" | "branchUuid">
  ) =>
    sendRequest<BasketState>({
      method: "GET",
      url: `/api/eshop/basket?${qs.stringify(props)}`
    });

  const renewExpiredBasket = async () =>
    sendRequest<BasketState>({
      method: "POST",
      url: `/api/eshop/renew-basket`
    });

  const addItemToBasket = async (
    props: Omit<AddItemToBasketProps, "userUuid">
  ) =>
    sendRequest<BasketState>({
      method: "POST",
      url: `/api/eshop/add-item`,
      body: props
    });

  const removeItemFromBasket = async (
    props: Omit<RemoveItemFromBasketProps, "userUuid">
  ) =>
    sendRequest<BasketState>({
      method: "POST",
      url: `/api/eshop/remove-item`,
      body: props
    });

  const removeItemTypeFromBasket = async (
    props: Omit<RemoveItemTypeFromBasketProps, "userUuid">
  ) =>
    sendRequest<BasketState>({
      method: "POST",
      url: `/api/eshop/remove-item-type`,
      body: props
    });

  const submitBasket = async (
    props: Omit<SubmitBasketProps, "userUuid" | "branchUuid">
  ) =>
    sendRequest<BasketState>({
      method: "POST",
      url: `/api/eshop/submit-basket`,
      body: props
    });

  const getOrderedItemsList = async (
    props: Omit<GetOrderedItemsListProps, "userUuid" | "branchUuids">
  ) =>
    sendRequest<GetOrderedItemsListResult>({
      method: "GET",
      url: `/api/eshop/ordered-items?${qs.stringify({
        ...props,
        filters: JSON.stringify(props.filters)
      })}`
    });

  const getLastMaintenances = async () =>
    sendRequest<GetLastMaintenancesListResult>({
      method: "GET",
      url: `/api/devices/last-maintenances`
    });

  const getContactInfo = async (props: GetContactInfoProps) =>
    sendRequest<GetContactInfoResult>({
      method: "GET",
      url: `/api/devices/contact-info/${props.uuid}`
    });

  return {
    activateUser,
    getWhoAmI,
    getDevices,
    getDetail,
    orderMaintenance,
    getMaintenances,
    getDocument,
    getDocumentByRecord,
    getDeviceDocumentsList,
    getDocumentsList,
    requestUsersBasket,
    addItemToBasket,
    removeItemFromBasket,
    submitBasket,
    getOrderedItemsList,
    renewExpiredBasket,
    getLastMaintenances,
    getContactInfo,
    removeItemTypeFromBasket
  };
};

export type ApplicationClient = ReturnType<typeof buildApplicationClient>;

export const ApplicationClientContext = createContext<ApplicationClient | null>(
  null
);

export const useApplicationClient = () => {
  const client = useContext(ApplicationClientContext);

  if (!client) {
    throw new Error(`Can not use application context outside its context`);
  }

  return client;
};
