import { useMemo } from "react";
import styled from "styled-components";

const Container = styled.div<{ floating?: boolean }>`
  display: ${(props) => (props.floating ? "none" : "flex")};
  padding: 16px;
  margin-bottom: 8px;

  background: ${(props) =>
    props.floating
      ? props.theme.backgroundShadeDarker
      : props.theme.backgroundShade};

  @media (min-width: 576px) {
    display: flex;
    margin-bottom: 25px;

    border-radius: 8px;
    position: ${(props) => (props.floating ? "absolute" : "relative")};
    right: ${(props) => (props.floating ? "12px" : 0)};
    top: ${(props) => (props.floating ? "12px" : 0)};
    width: ${(props) => (props.floating ? "400px" : "auto")};
    box-shadow: ${(props) =>
      props.floating ? "rgba(0, 0, 0, 0.16) 0px 1px 4px" : "npne"};
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.textContrastColor};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

const MissingAmount = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${(props) => props.theme.textColor};
`;

const Bar = styled.div`
  height: 30px;
  display: flex;
  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const Filled = styled.div<{ ratio: number }>`
  height: 100%;
  background: ${(props) => props.theme.primary};
  flex: ${(props) => props.ratio};
  transition: all 0.1s ease-in;
`;

const Empty = styled.div<{ ratio: number }>`
  height: 100%;
  background: ${(props) => props.theme.backgroundLighter};
  flex: ${(props) => props.ratio};
  transition: all 0.1s ease-in;
`;

const Added = styled.div<{ ratio: number }>`
  height: 100%;
  background: ${(props) => props.theme.confirm};
  flex: ${(props) => props.ratio};
  transition: all 0.1s ease-in;
`;

export function StatusInfo(props: {
  dueAmount: number;
  fulfilledAmount: number;
  added: number;
  floating?: boolean;
}) {
  const { dueAmount, fulfilledAmount, floating, added } = props;

  const overLimit = useMemo(
    () => dueAmount - fulfilledAmount < 0,
    [dueAmount, fulfilledAmount]
  );

  return (
    <Container floating={floating}>
      <Title>Odběr kávy:</Title>
      <Content>
        <MissingAmount>
          Zbývá:{" "}
          {overLimit
            ? 0
            : Math.max(dueAmount - fulfilledAmount - added, 0).toFixed(2)}{" "}
          kg
        </MissingAmount>
        <Bar>
          <Filled ratio={fulfilledAmount} />
          {!overLimit && (
            <Added
              ratio={
                added > dueAmount - fulfilledAmount
                  ? dueAmount - fulfilledAmount
                  : added
              }
            />
          )}
          {!overLimit && <Empty ratio={dueAmount - fulfilledAmount - added} />}
        </Bar>
      </Content>
    </Container>
  );
}
