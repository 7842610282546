import { useNavigate } from "react-router-dom";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { toast } from "react-toastify";
import { assertExistence } from "@shared/common/assert";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { BasketState } from "@shared/domain/basket";
import { PriceType } from "@shared/domain/products.types";

import { useLogger } from "./../../common/use-logger";
import { useAuthState } from "../../root/hooks/use-auth-state";
import { useApplicationClient } from "../../root/hooks/use-application-client";
import { useApplicationConfig } from "../../root/hooks/use-application-config";

interface BasketLoadingState {
  loading: true;
}

export interface BasketBranchProduct {
  uuid: string;
  category: string;
  categoryOrder: number;
  title: string;
  description: string;
  priceInCZK?: number;
  priceInPTS?: number;
  amountInCZK: number;
  amountInPTS: number;
  inStock: number;
}

interface BasketSummaryItem {
  title: string;
  amount: number;
  totalPrice: number;
  priceType: PriceType.CZK | PriceType.PTS;
  productUuid: string;
}

interface BranchSummary {
  name: string;
  items: BasketSummaryItem[];
  priceInCzk: number;
  uuid: string;
}

interface BasketSummary {
  isThereOrder: boolean;
  branchItems: Record<string, BranchSummary>;
  totalPrice: number;
  totalWeight: number;
  totalBenefitPoints: number;
  transportFee: number;
  transportAmount: number;
  transportationPriceLimit: number;
}

interface BasketLoadedState {
  loading: false;
  isExpired: boolean;
  orderedCoffeeStatus: {
    dueAmount: number;
    fulfilledAmount: number;
  };
  benefitPoints: number;
  branchProducts: Record<string, BasketBranchProduct[]>;
  basketSummary: BasketSummary;
  addItemToBasket: (props: {
    branchUuid: string;
    paymentType: PriceType.CZK | PriceType.PTS;
    productUuid: string;
    amount: number;
  }) => Promise<void>;
  removeItemFromBasket: (props: {
    branchUuid: string;
    paymentType: PriceType.CZK | PriceType.PTS;
    productUuid: string;
  }) => Promise<void>;
  removeItemTypeFromBasket: (props: {
    branchUuid: string;
    paymentType: PriceType.CZK | PriceType.PTS;
    productUuid: string;
  }) => Promise<void>;
  submitBasket: (props: { note: string; orderNumber: string }) => Promise<void>;
  renewExpiredBasket: () => Promise<void>;
  resetBasket: () => Promise<void>;
}

type BasketClient = BasketLoadingState | BasketLoadedState;

export function isBasketLoadedGuard(
  value: BasketClient
): value is BasketLoadedState {
  return !value.loading;
}

function mapBasketToBranchProducts(
  basketState: BasketState,
  branches: string[]
): Record<string, BasketBranchProduct[]> {
  const branchProducts = branches.reduce<Record<string, BasketBranchProduct[]>>(
    (memo, branchUuid) => {
      const productsInBranch = basketState.availableProducts
        .filter(
          (item) =>
            item.branchUuid === undefined || item.branchUuid === branchUuid
        )
        .reduce<BasketBranchProduct[]>((memo, product) => {
          const existingProduct = memo.find(
            (left) => left.uuid === product.productUuid
          );

          if (existingProduct) {
            existingProduct.priceInCZK =
              product.pricePerUnitCZK !== undefined
                ? product.pricePerUnitCZK
                : existingProduct.priceInCZK;
            existingProduct.priceInPTS =
              product.pricePerUnitPTS !== undefined
                ? product.pricePerUnitPTS
                : existingProduct.priceInPTS;
          } else {
            memo.push({
              uuid: product.productUuid,
              category: product.category,
              categoryOrder: product.categoryOrder,
              title: product.title,
              description: product.description,
              priceInCZK: product.pricePerUnitCZK,
              priceInPTS: product.pricePerUnitPTS,
              inStock: product.inStock,
              amountInCZK:
                basketState.basketItems.find(
                  (left) =>
                    left.branchUuid === branchUuid &&
                    left.paymentType === PriceType.CZK &&
                    left.productUuid === product.productUuid
                )?.amount || 0,
              amountInPTS:
                basketState.basketItems.find(
                  (left) =>
                    left.branchUuid === branchUuid &&
                    left.paymentType === PriceType.PTS &&
                    left.productUuid === product.productUuid
                )?.amount || 0
            });
          }

          return memo;
        }, []);

      memo[branchUuid] = productsInBranch;
      return memo;
    },
    {}
  );

  return branchProducts;
}

function mapBasketToBasketSummary(
  basketState: BasketState,
  branches: { uuid: string; name: string }[],
  coffeeCategories: string[]
): BasketSummary {
  let totalBasketPrice = 0;
  let totalBenefitPoints = 0;
  let totalWeight = 0;

  const items = basketState.basketItems.reduce<Record<string, BranchSummary>>(
    (memo, item) => {
      if (!memo[item.branchUuid]) {
        const branch = assertExistence(
          branches.find((left) => left.uuid === item.branchUuid)
        );

        memo[item.branchUuid] = {
          name: branch.name,
          items: [],
          priceInCzk: 0,
          uuid: branch.uuid
        };
      }

      const product = assertExistence(
        basketState.availableProducts.find((left) => {
          if (item.paymentType === PriceType.CZK) {
            return (
              left.branchUuid === item.branchUuid &&
              item.productUuid === left.productUuid
            );
          } else {
            return (
              left.branchUuid === undefined &&
              item.productUuid === left.productUuid
            );
          }
        })
      );

      const totalPrice =
        item.paymentType === PriceType.CZK
          ? item.amount * assertExistence(product.pricePerUnitCZK)
          : item.amount * assertExistence(product.pricePerUnitPTS);

      // Only products for money and coffee are added to fulfilled amount of coffee weight
      if (
        item.paymentType === PriceType.CZK &&
        coffeeCategories.includes(product.category)
      ) {
        totalWeight += product.weight * item.amount;
      }

      if (item.paymentType === PriceType.CZK) {
        totalBasketPrice += totalPrice;
        memo[item.branchUuid].priceInCzk += totalPrice;
      } else {
        totalBenefitPoints += totalPrice;
      }

      memo[item.branchUuid].items.push({
        title: product.title,
        amount: item.amount,
        totalPrice: totalPrice,
        priceType: item.paymentType,
        productUuid: item.productUuid
      });

      return memo;
    },
    {}
  );

  const transport = Object.values(items).reduce(
    (memo, value) => {
      if (value.priceInCzk < basketState.transportationPriceLimit) {
        memo.fee += basketState.transportationPrice;
        memo.amount++;
      }

      return memo;
    },
    { fee: 0, amount: 0 }
  );

  return {
    isThereOrder: Object.values(items).length > 0,
    totalPrice: totalBasketPrice + transport.fee,
    totalWeight,
    totalBenefitPoints: totalBenefitPoints,
    branchItems: items,
    transportFee: transport.fee,
    transportAmount: transport.amount,
    transportationPriceLimit: basketState.transportationPriceLimit
  };
}

export const BasketContext = createContext<BasketClient | null>(null);

export const useBuildBasketClient = (): BasketClient => {
  const [basketState, setBasketState] = useState<BasketState | null>(null);
  const [branchProducts, setBranchProducts] = useState<
    Record<string, BasketBranchProduct[]>
  >({});
  const [basketSummary, setBasketSummary] = useState<BasketSummary | null>(
    null
  );

  const [benefitPoints, setBenefitPoints] = useState<number | null>(null);
  const [status, setStatus] = useState<{
    dueAmount: number;
    fulfilledAmount: number;
  } | null>(null);
  const [isExpired, setIsExpired] = useState<boolean | null>(null);

  const {
    requestUsersBasket,
    renewExpiredBasket: renewExpiredBasketApiCall,
    addItemToBasket: addItemToBasketApiCall,
    removeItemFromBasket: removeItemFromBasketApiCall,
    removeItemTypeFromBasket: removeItemTypeFromBasketApiCall,
    submitBasket: submitBasketApiCall
  } = useApplicationClient();
  const authState = useAuthState();
  const navigate = useNavigate();
  const logger = useLogger("useBuildBasketClient");
  const config = useApplicationConfig();

  const startLoading = useCallback(() => {
    setBasketState(null);
  }, []);

  const updateBasketState = useCallback(
    (basketState: BasketState) => {
      setBasketState(basketState);

      setBranchProducts(
        mapBasketToBranchProducts(
          basketState,
          authState.branches.map((item) => item.uuid)
        )
      );

      setBasketSummary(
        mapBasketToBasketSummary(
          basketState,
          authState.branches,
          config.coffeeCategories
        )
      );
      setBenefitPoints(basketState.availableBenefitPoints);
      setStatus({
        dueAmount: basketState.dueAmount,
        fulfilledAmount: basketState.fulfilledAmount
      });
      setIsExpired(basketState.expired);
    },
    [authState, config]
  );

  const loadBasket = useCallback(async () => {
    startLoading();

    const response = await requestUsersBasket({ requestNew: false });

    if (isResponseSuccess(response)) {
      updateBasketState(response.payload);
    } else {
      toast.error(`Nastala chyba při načítání košíku 🤕`);
      logger.error(`Error fetching basket`, response);
    }
  }, [startLoading, requestUsersBasket, updateBasketState, logger]);

  const resetBasket = useCallback(async () => {
    startLoading();

    const response = await requestUsersBasket({ requestNew: true });

    if (isResponseSuccess(response)) {
      updateBasketState(response.payload);
    } else {
      toast.error(`Nastala chyba při mázání košíku 🤕`);
      logger.error(`Error fetching basket`, response);
    }
  }, [startLoading, requestUsersBasket, updateBasketState, logger]);

  const renewExpiredBasket = useCallback(async () => {
    const response = await renewExpiredBasketApiCall();

    if (isResponseSuccess(response)) {
      updateBasketState(response.payload);
      toast.success(`Košík obnoven`);
    } else {
      toast.error(`Nastala chyba při obnovování košíku 🤕`);
      logger.error(`Error adding item to basket`, response);
      loadBasket();
    }
  }, [renewExpiredBasketApiCall, updateBasketState, loadBasket, logger]);

  const addItemToBasket = useCallback(
    async (props: {
      branchUuid: string;
      productUuid: string;
      amount: number;
      paymentType: PriceType.CZK | PriceType.PTS;
    }) => {
      const response = await addItemToBasketApiCall(props);

      if (isResponseSuccess(response)) {
        updateBasketState(response.payload);
        toast.success(`Položka přidána do košíku`);
      } else {
        toast.error(`Nastala chyba při přidávání produktu do košíku 🤕`);
        logger.error(`Error adding item to basket`, response);
        loadBasket();
        navigate("/eshop");
      }
    },
    [addItemToBasketApiCall, updateBasketState, loadBasket, navigate, logger]
  );

  const removeItemFromBasket = useCallback(
    async (props: {
      branchUuid: string;
      productUuid: string;
      paymentType: PriceType.CZK | PriceType.PTS;
    }) => {
      const response = await removeItemFromBasketApiCall(props);

      if (isResponseSuccess(response)) {
        toast.success(`Položka odebrána z košíku`);
        updateBasketState(response.payload);
      } else {
        toast.error(`Nastala chyba při odebírání produktu z košíku 🤕`);
        logger.error(`Error removing item from basket`, response);
        loadBasket();
        navigate("/eshop");
      }
    },
    [
      removeItemFromBasketApiCall,
      updateBasketState,
      loadBasket,
      navigate,
      logger
    ]
  );

  const removeItemTypeFromBasket = useCallback(
    async (props: {
      branchUuid: string;
      productUuid: string;
      paymentType: PriceType.CZK | PriceType.PTS;
    }) => {
      const response = await removeItemTypeFromBasketApiCall(props);

      if (isResponseSuccess(response)) {
        toast.success(`Položka odebrána z košíku`);
        updateBasketState(response.payload);
      } else {
        toast.error(`Nastala chyba při odebírání produktu z košíku 🤕`);
        logger.error(`Error removing item from basket`, response);
        loadBasket();
        navigate("/eshop");
      }
    },
    [
      removeItemTypeFromBasketApiCall,
      updateBasketState,
      loadBasket,
      navigate,
      logger
    ]
  );

  const submitBasket = useCallback(
    async (props: { note: string; orderNumber: string }) => {
      const response = await submitBasketApiCall(props);

      if (isResponseSuccess(response)) {
        toast.success(`Objednávka odeslána`);
        updateBasketState(response.payload);
      } else {
        toast.error(`Nastala chyba při objednávce 🤕`);
        logger.error(`Error removing item from basket`, response);
        loadBasket();
      }
    },
    [submitBasketApiCall, updateBasketState, loadBasket, logger]
  );

  useEffect(() => {
    loadBasket();
  }, [loadBasket]);

  return useMemo(() => {
    if (
      status === null ||
      benefitPoints === null ||
      basketState === null ||
      basketSummary === null ||
      isExpired === null
    ) {
      return {
        loading: true
      };
    }

    return {
      loading: false,
      orderedCoffeeStatus: status,
      benefitPoints,
      branchProducts,
      basketSummary,
      addItemToBasket,
      removeItemFromBasket,
      renewExpiredBasket,
      resetBasket,
      submitBasket,
      isExpired,
      removeItemTypeFromBasket
    };
  }, [
    status,
    addItemToBasket,
    branchProducts,
    basketSummary,
    benefitPoints,
    basketState,
    removeItemFromBasket,
    renewExpiredBasket,
    submitBasket,
    resetBasket,
    isExpired,
    removeItemTypeFromBasket
  ]);
};

export const useBasket = () => {
  const context = useContext(BasketContext);

  if (!context) {
    throw new Error(`Can not use Basket outside it's context`);
  }

  return context;
};
