import styled from "styled-components";

export const InfiniteListColumn = styled.span`
  padding: 4px 8px;
  flex-basis: auto;
  flex: 1;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  animation: fadeIn 0.1s ease-in 1;

  &.loading {
    background: #cfcfcf;
    height: 50%;
    border-radius: 20px;
    align-self: center;
    transform: scale(0.7);
    transform-origin: left;
    animation: fadeIn 1s ease-in 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
