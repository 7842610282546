import { useCallback, useMemo } from "react";
import styled from "styled-components";

import { ReactComponent as BackIcon } from "./arrow-back.svg";
import { Modal } from "../ui/modal/modal";
import { PageTitle } from "../ui/pages/page-title";
import { CardHeader, CardTitle, DetailCard } from "../ui/pages/detail-card";
import { Tabs } from "../ui/tabs/tabs";
import { StatusInfo } from "./basket-status-info";
import { ProductView } from "./product-view";
import { isBasketLoadedGuard, useBasket } from "./hooks/use-basket";
import { uniq } from "lodash";
import { useParams } from "react-router-dom";
import { useAuthState } from "../root/hooks/use-auth-state";
import { assertExistence } from "../../../shared/src/common/assert";

const PageDiv = styled.div`
  overflow: hidden;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  background: ${(props) => props.theme.backgroundLighter};

  @media (min-width: 576px) {
    padding: 25px;

    max-width: 1350px;
    max-height: 840px;
    width: 95vw;
    height: 95vh;
  }
`;

export function OrderForBranchModal(props: {
  status: { dueAmount: number; fulfilledAmount: number };
  onClose: () => void;
}) {
  const { onClose, status } = props;

  const basket = useBasket();
  const { uuid } = useParams();
  const authState = useAuthState();

  const branch = useMemo(
    () =>
      assertExistence(authState.branches.find((item) => item.uuid === uuid)),
    [authState, uuid]
  );

  const renderTabs = useCallback(
    (category: string) => {
      return <ProductView branchUuid={branch.uuid} category={category} />;
    },
    [branch]
  );

  const tabs = useMemo(() => {
    if (!isBasketLoadedGuard(basket)) {
      return [];
    }

    return uniq(
      basket.branchProducts[branch.uuid]
        .sort((a, b) => a.categoryOrder - b.categoryOrder)
        .map((item) => item.category)
    );
  }, [basket, branch]);

  if (!isBasketLoadedGuard(basket)) {
    return <></>;
  }

  return (
    <Modal onClose={props.onClose}>
      <PageDiv>
        <StatusInfo
          floating
          dueAmount={status.dueAmount}
          fulfilledAmount={status.fulfilledAmount}
          added={basket.basketSummary.totalWeight}
        />

        <PageTitle onClick={onClose}>
          <BackIcon />
          <span>Zpět do košíku</span>
        </PageTitle>

        <DetailCard>
          <CardHeader>
            <CardTitle>Objednávka pro - {branch.name}</CardTitle>
          </CardHeader>

          <Tabs tabs={tabs} renderTabs={renderTabs} />
        </DetailCard>
      </PageDiv>
    </Modal>
  );
}
