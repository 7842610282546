import { ReactNode, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useLogger } from "../../common/use-logger";

const Container = styled.div`
  overflow: hidden;
  height: 100%;
  margin: 8px 0;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const TabsHeader = styled.div<{ compact?: boolean }>`
  margin: 0 0 8px 0;
  padding: 0 16px 4px 16px;
  border-bottom: 1px solid black;
  border-bottom: ${(props) => "2px solid " + props.theme.backgroundShadeDarker};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border: 1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
  }

  @media (min-width: 576px) {
    margin: ${(props) => (props.compact ? "0" : " 0 25px 8px 25px")};
    padding: 0 0 4px 0;
    margin-bottom: 16px;

    justify-content: flex-start;
  }
`;

const TabItem = styled.span<{ active?: boolean }>`
  padding: 4px 0 8px 0;
  position: relative;
  top: 1px;
  margin-left: 25px;

  color: ${(props) =>
    props.active ? props.theme.primary : props.theme.textColor};
  font-size: 13px;
  font-weight: ${(props) => (props.active ? 700 : 600)};

  transition: all 0.15s ease-in;
  cursor: pointer;
  text-align: center;

  &.active {
    font-weight: 600;
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    transform: scale(1.02);
  }

  @media (min-width: 576px) {
    padding: 4px 0;
    font-size: 14px;
  }
`;

const TabsContent = styled.div<{ compact?: boolean }>`
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (min-width: 576px) {
    margin: ${(props) => (props.compact ? "0" : " 0 25px 25px 25px")};
  }
`;

export interface TabOption {
  label: string;
  path: string;
}

export const RoutedTabs = (props: {
  tabs: readonly TabOption[];
  renderTabs: (openedTabPath: string) => ReactNode;
  compact?: boolean;
}) => {
  const { tabs, renderTabs, compact } = props;

  const navigate = useNavigate();
  const params = useParams();
  const logger = useLogger("RoutedTabs");

  useEffect(() => {
    // We use * for route params path...
    if (params["*"] === "" && tabs[0]?.path.length > 0) {
      logger.debug(
        `No tab selected - ${params["*"]}, navigating to first tab - ${tabs[0]?.path}`
      );
      navigate(`./${tabs[0]?.path}`);
    }
  }, [params, navigate, tabs, logger]);

  return (
    <Container>
      <TabsHeader compact={compact}>
        {tabs.map((item) => (
          <TabItem
            // We use * for route params path...
            active={item.path === params["*"]}
            onClick={() => navigate(item.path)}
            key={item.path}
          >
            {item.label}
          </TabItem>
        ))}
      </TabsHeader>

      <TabsContent compact={compact}>
        <Routes>
          {tabs.map((tab) => (
            <Route
              key={tab.path}
              path={`/${tab.path}/*`}
              element={<>{renderTabs(tab.path)}</>}
            />
          ))}
        </Routes>
      </TabsContent>
    </Container>
  );
};

export const Tabs = (props: {
  tabs: readonly string[];
  renderTabs: (openedTab: string) => ReactNode;
  compact?: boolean;
}) => {
  const { tabs, renderTabs, compact } = props;
  const [openedTab, setOpenedTab] = useState(tabs[0]);

  return (
    <Container>
      <TabsHeader compact={compact}>
        {tabs.map((item) => (
          <TabItem
            active={item === openedTab}
            onClick={() => setOpenedTab(item)}
            key={item}
          >
            {item}
          </TabItem>
        ))}
      </TabsHeader>

      <TabsContent compact={compact}>{renderTabs(openedTab)}</TabsContent>
    </Container>
  );
};
