import { useCallback } from "react";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { assertExistence } from "@shared/common/assert";
import { DocumentSuffix } from "@shared/domain/document.types";
import { toast } from "react-toastify";

import { useApplicationClient } from "../root/hooks/use-application-client";
import { Button } from "../ui/button/button";
import { useLogger } from "../common/use-logger";

function mapSuffixToMime(type: DocumentSuffix) {
  switch (type) {
    case DocumentSuffix.Pdf:
      return "application/pdf";
    case DocumentSuffix.Jpg:
      return "image/jpeg";
    default:
      throw new Error(`Unknown type`);
  }
}

function mapButtonName(type: DocumentSuffix) {
  switch (type) {
    case DocumentSuffix.Pdf:
      return "PDF";
    case DocumentSuffix.Jpg:
      return "JPG";
    default:
      throw new Error(`Unknown type ${type}`);
  }
}

export function DocumentDownloadRow(props: {
  documentUuid: string;
  type: DocumentSuffix;
}) {
  const { documentUuid, type } = props;

  const { getDocument } = useApplicationClient();
  const logger = useLogger("DocumentDownloadRow");

  const handleDownload = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const response = await getDocument({
        uuid: assertExistence(documentUuid)
      });

      function _base64ToArrayBuffer(base64: string) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
      }

      if (isResponseSuccess(response)) {
        logger.debug(
          `Received document data, creating blob, fileURL and opening window`
        );
        const blob = new Blob(
          [_base64ToArrayBuffer(assertExistence(response.payload.data))],

          {
            type: mapSuffixToMime(type)
          }
        );
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL);
      } else {
        toast.error("Nastala chyba při načítání dat 🤕");
        logger.error(`Error while downloading document`);
      }
    },
    [documentUuid, getDocument, type, logger]
  );

  return (
    <Button
      style={{ padding: `0 8px`, height: 20, marginLeft: 6 }}
      onClick={handleDownload}
    >
      {mapButtonName(type)}
    </Button>
  );
}
