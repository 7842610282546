import styled from "styled-components";

export const DashboardSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;

  background: ${(props) => props.theme.backgroundShade};

  @media (min-width: 576px) {
    margin-bottom: 25px;
  }
`;
