import styled from "styled-components";

export const Card = styled.div<{ wide?: boolean }>`
  border-radius: 5px;
  height: 100%;

  background: ${(props) => props.theme.backgroundLighter};

  @media (min-width: 576px) {
    width: ${(props) => (props.wide ? "700px" : "300px")};
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
`;

export const CardHeader = styled.h2`
  padding: 16px;
  margin: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 22px;
  font-weight: 600;
  color: ${(props) => props.theme.textContrastColor};

  background: ${(props) => props.theme.background};

  @media (min-width: 576px) {
    padding: 25px;
  }
`;

export const CardContent = styled.div`
  padding: 16px 16px 0 16px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (min-width: 576px) {
    padding: 25px 25px 0 25px;
  }
`;

export const CardFooter = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;

  @media (min-width: 576px) {
    padding: 25px;
  }
`;
