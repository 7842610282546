import styled from "styled-components";
import { useCallback } from "react";
import { GetDevicesDetailResult } from "@shared/features/devices-feature";

import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { LoadInfiniteListProps } from "../ui/infinite-list/use-infinite-list";

const columns: ListColumn<GetDevicesDetailResult["configuration"][0]>[] = [
  {
    title: "Název položky",
    dataIndex: "productName",
    render: (data) => (
      <TruncatedText id={`${Math.random()}-productName`}>
        {data.productName}
      </TruncatedText>
    ),
    minWidth: 100,
    flex: 2
  },
  {
    title: "Množství",
    dataIndex: "amount",
    render: (data) => (
      <TruncatedText id={`${Math.random()}-amount`}>
        {`${data.amount}x`}
      </TruncatedText>
    ),
    minWidth: 60,
    flex: 1
  },
  {
    title: "Popis",
    dataIndex: "productDescription",
    render: (data) => (
      <TruncatedText id={`${Math.random()}-productDescription`}>
        {data.productDescription}
      </TruncatedText>
    ),
    minWidth: 150,
    flex: 3
  },
  {
    title: "Poznámka",
    dataIndex: "note",
    render: (data) => (
      <TruncatedText id={`${Math.random()}-note`}>{data.note}</TruncatedText>
    ),
    minWidth: 150,
    flex: 3
  }
];

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DevicesDetailConfiguration = (props: {
  data: GetDevicesDetailResult;
}) => {
  const { data } = props;

  const fetchData = useCallback(
    async (props: LoadInfiniteListProps) => {
      return {
        count: data.configuration.length,
        rows: data.configuration
      };
    },
    [data]
  );

  return (
    <DevicesPageContext>
      <InfiniteList
        columns={columns}
        loadRows={fetchData}
        dependencies={[fetchData]}
      />
    </DevicesPageContext>
  );
};
