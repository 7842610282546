import styled from "styled-components";

import { ReactComponent as Icon } from "./no-data.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Text = styled.span`
  margin-top: 16px;
  color: #aaaaaa;
  font-weight: 500;
  font-size: 16px;
`;

export const NoData = () => {
  return (
    <Container>
      <Icon />
      <Text>Žádná data</Text>
    </Container>
  );
};
