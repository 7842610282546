import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { DocumentSuffix } from "@shared/domain/document.types";
import {
  GetDevicesDetailResult,
  GetMaintenancesListItem
} from "@shared//features/devices-feature";
import { isResponseSuccess } from "@shared/api/api-contracts";
import { toast } from "react-toastify";

import { DocumentDownloadRow } from "../documents/document-download-row";
import { useApplicationClient } from "../root/hooks/use-application-client";
import { useGlobalDataVersion } from "../root/hooks/use-globa-data-version";
import { FiltersWithSearch, useActiveFilters } from "../ui/filters/filters";
import { InfiniteList, ListColumn } from "../ui/infinite-list/infinite-list";
import { LoadInfiniteListProps } from "../ui/infinite-list/use-infinite-list";
import { ListHeader } from "../ui/pages/list-header";
import { TruncatedText } from "../ui/truncated-text/truncated-text";
import { useLogger } from "../common/use-logger";

const useColumns = () => {
  return useMemo(() => {
    const columns: ListColumn<GetMaintenancesListItem>[] = [
      {
        title: "Datum",
        dataIndex: "date",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-date`}>{data.date}</TruncatedText>
        ),
        minWidth: 30,
        flex: 1
      },
      {
        title: "Číslo servisní zakázky",
        dataIndex: "number",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-number`}>
            {data.number}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1.5
      },
      {
        title: "Popis",
        dataIndex: "reason",
        render: (data) => (
          <TruncatedText
            id={`${data.uuid}-reason`}
          >{`${data.reason}`}</TruncatedText>
        ),
        minWidth: 100,
        flex: 1.8
      },
      {
        title: "Technik",
        dataIndex: "technitian",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-technician`}>
            {data.technician}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1.5
      },
      {
        title: "Datum plán. realizace",
        dataIndex: "plannedDate",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-plannedDate`}>
            {data.plannedDate}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1
      },
      {
        title: "Stav",
        dataIndex: "status",
        render: (data) => (
          <TruncatedText id={`${data.uuid}-status`}>
            {data.status}
          </TruncatedText>
        ),
        minWidth: 100,
        flex: 1
      },
      {
        title: "Ke stažení",
        dataIndex: "documentUuid",
        render: (item) => (
          <>
            {item.documentUuid && (
              <DocumentDownloadRow
                documentUuid={item.documentUuid}
                type={DocumentSuffix.Pdf}
              />
            )}
          </>
        ),
        minWidth: 60,
        flex: 1
      }
    ];

    return columns;
  }, []);
};

const filters = [
  {
    name: "Typ servisu - TODO",
    column: "test",
    values: [
      { id: "1", name: "Instalační Servis - TODO" },
      { id: "2", name: "Revize - TODO" }
    ]
  }
];

const DevicesPageContext = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.15s ease-in 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DevicesDetailMaintanences = (props: {
  data: GetDevicesDetailResult;
}) => {
  const { data } = props;

  const [search, setSearch] = useState("");
  const activeFilters = useActiveFilters();
  const api = useApplicationClient();
  const { version } = useGlobalDataVersion();
  const columns = useColumns();
  const logger = useLogger("DevicesDetailMaintanences");

  const fetchData = useCallback(
    async (props: LoadInfiniteListProps) => {
      const response = await api.getMaintenances({
        startIndex: props.startIndex,
        stopIndex: props.stopIndex,
        search: search,
        sortColumn: props.sortColumn,
        sortOrder: props.sortOrder,
        filters: activeFilters,
        deviceUuid: data.uuid
      });
      if (isResponseSuccess(response)) {
        logger.debug(
          `Fetched device maintenances list data from ${props.startIndex} to ${props.stopIndex}`
        );
        return response.payload;
      } else {
        toast.error("Nastala chyba při načítání dat 🤕");
        logger.error(`There was an error loading list`, response);
        return {
          count: 0,
          rows: []
        };
      }
    },
    [api, data.uuid, activeFilters, search, logger]
  );

  return (
    <DevicesPageContext>
      <ListHeader compact>
        <FiltersWithSearch
          filters={filters}
          search={search}
          onSearchChanged={setSearch}
        />
      </ListHeader>

      <InfiniteList
        columns={columns}
        loadRows={fetchData}
        dependencies={[fetchData, version]}
      />
    </DevicesPageContext>
  );
};
