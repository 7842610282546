import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { CSSProperties } from "styled-components";

import { ReactComponent as SearchIcon } from "./search.svg";

const Label = styled.label`
  box-sizing: border-box;
  position: relative;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  input {
    box-sizing: border-box;
    height: 35px;
    width: 100%;
    margin: 0;
    padding-left: 35px;
    border: none;
    border-radius: 25px;

    color: ${(props) => props.theme.textColor};
    font-size: 12px;
    font-weight: 500;

    background-color: ${(props) => props.theme.backgroundLighte};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &::placeholder {
      color: ${(props) => props.theme.textColor};
      font-size: 12px;
      font-weight: 400;
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 20px;
    height: 20px;

    fill: ${(props) => props.theme.textColor};
  }
`;

export const Search = (props: {
  placeholder?: string;
  onChange: (value: string) => void;
  value?: string;
  autofocus?: boolean;
  style?: CSSProperties;
}) => {
  const { placeholder, value, onChange, autofocus } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const onChangeHandler = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      debouncedOnChange(e.currentTarget.value);
      setLocalValue(e.currentTarget.value);
    },
    [debouncedOnChange]
  );

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (autofocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [autofocus]);

  return (
    <Label style={props.style}>
      <input
        placeholder={placeholder}
        onChange={onChangeHandler}
        value={localValue || ""}
        ref={inputRef}
      />
      <SearchIcon />
    </Label>
  );
};
