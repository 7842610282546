import moment from "moment";
import { useMemo } from "react";

export const useLogger = (prefix?: string) => {
  return useMemo(() => {
    const formatMessage = (message: string, level: string) =>
      `${level.toUpperCase()} ${moment().format(
        "DD.MM.YYYY HH:mm:ss"
      )} - [${prefix}] ${message}`;

    return {
      verbose: (message: string, ...args: unknown[]) =>
        console.debug(formatMessage(message, "VERBOSE"), ...args),
      debug: (message: string, ...args: unknown[]) =>
        console.debug(formatMessage(message, "DEBUG"), ...args),
      log: (message: string, ...args: unknown[]) =>
        console.log(formatMessage(message, "LOG"), ...args),
      warn: (message: string, ...args: unknown[]) =>
        console.warn(formatMessage(message, "WARN"), ...args),
      error: (message: string, ...args: unknown[]) =>
        console.error(formatMessage(message, "ERROR"), ...args)
    };
  }, [prefix]);
};
