import styled from "styled-components";

export const InputComponent = styled.textarea<{ error: boolean }>`
  flex: 1;
  margin: 0;
  padding: 8px 16px;

  border-radius: 5px;
  border: ${(props) =>
    props.error
      ? "1px solid" + props.theme.warn
      : "1px solid" + props.theme.backgroundShadeDarker};

  font-size: 14px;
  font-weight: 500;
  background: ${(props) => props.theme.backgroundLighter};
  color: ${(props) => props.theme.textColor};

  font-family: "Raleway", sans-serif;

  &:focus {
    outline: ${(props) =>
      props.error ? "none" : "1px solid" + props.theme.primary};
  }

  &::placeholder {
    color: ${(props) => props.theme.textColorLighter};
    font-size: 13px;
    font-weight: 600;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.warn};
  font-size: 13px;
  font-weight: 700;
  padding: 0 8px;
  margin-top: 8px;
`;

interface InputProps {
  name?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  touched?: boolean;
  rows?: number;
  onChange?: {
    /** Classic React change handler, keyed by input name */
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur?: {
    /** Classic React blur handler, keyed by input name */
    (e: React.FocusEvent<any>): void;
    /** Preact-like linkState. Will return a handleBlur function. */
    <T = string | any>(fieldOrEvent: T): T extends string
      ? (e: any) => void
      : void;
  };
}

export const TextArea = (props: InputProps) => {
  const {
    name,
    rows,
    placeholder,
    required,
    onBlur,
    onChange,
    value,
    error,
    touched
  } = props;

  return (
    <Container>
      <InputComponent
        name={name}
        placeholder={placeholder}
        required={required}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        error={(error && touched) || false}
        rows={rows}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};
