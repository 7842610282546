import { Routes, Route } from "react-router-dom";

import { DevicesDetail } from "./devices-detail";
import { DevicesList } from "./devices-list";

export const Devices = () => {
  return (
    <Routes>
      <Route path="/:uuid/*" element={<DevicesDetail />} />
      <Route path="/" element={<DevicesList />} />
    </Routes>
  );
};
